import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import * as React from "react";

import { Label, Root, Value } from "./VehicleDataContainer.sc";

interface IVehicleDataContainer {
    label: string | JSX.Element;
    value: string;
    infoIconText?: React.ReactNode;
}

const VehicleDataContainer: React.FunctionComponent<IVehicleDataContainer> = ({ label, value, infoIconText }) => {
    return (
        <Root>
            <Label>{label}</Label>
            <Value>
                {value}
                {infoIconText && <InfoIcon alignment="center" infoText={infoIconText} />}
            </Value>
        </Root>
    );
};

export default VehicleDataContainer;
