import styled from "styled-components";

export const Content = styled.div`
    margin: 20px 0;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 40px 0;
    }
`;

export const ResidualText = styled.p`
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 20px 0;
    padding: 0 20px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        line-height: 24px;
        padding: 0 20px 0 0;
        margin: 0 0 30px 0;
    }
`;
