import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import Anchor from "@src/shared/anchor/Anchor";
import Equipment from "@src/shared/vehicleDetail/equipment/EquipmentContainer";
import React from "react";
import { FormattedMessage } from "react-intl";

import DealerInfo from "../dealer/DealerInfo";
import * as sc from "./DesktopContainer.sc";

interface DesktopContainerProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    carDataContent: React.ReactElement;
    finCalcLightbox?: React.ReactElement;
}

const DesktopContainer: React.FC<DesktopContainerProps> = ({ vehicle, children, carDataContent, finCalcLightbox }) => {
    const carDataElement = React.useRef<HTMLDivElement>(null);
    const dealerInfoDesktopRef = React.useRef<HTMLDivElement | null>(null);

    return (
        <sc.DesktopContainer>
            <Anchor id="vehicleData" />
            <sc.DropdownHeadline>
                <sc.HorizontalLine />
                <sc.HeadlineText>
                    <FormattedMessage id="leaseme.vehicleData" defaultMessage="Fahrzeugdaten" />
                </sc.HeadlineText>
            </sc.DropdownHeadline>
            <div ref={carDataElement}>
                <sc.CarDataEquipmentWrapper>
                    {carDataContent}

                    {/* EquipmentContainer Section */}
                    {vehicle.nwSeriesEquipment && vehicle.nwSeriesEquipment.length > 0 ? (
                        <Equipment equipment={vehicle.nwSeriesEquipment} />
                    ) : (
                        vehicle.equipment && vehicle.equipment.length > 0 && <Equipment equipment={vehicle.equipment} />
                    )}

                    <sc.MobileBorderSmall />
                </sc.CarDataEquipmentWrapper>

                {finCalcLightbox}

                {/* Dealerinfo Section */}
                {vehicle.dealer && (
                    <sc.DetailInfoWrapper ref={dealerInfoDesktopRef}>
                        <sc.DropdownHeadline>
                            <sc.HorizontalLine />
                            <sc.HeadlineText>
                                <FormattedMessage id="leaseme.dealerInformation" defaultMessage="Händlerinformation" />
                            </sc.HeadlineText>
                        </sc.DropdownHeadline>
                        <DealerInfo dealer={vehicle.dealer} />
                    </sc.DetailInfoWrapper>
                )}
            </div>
            {children}
        </sc.DesktopContainer>
    );
};

export default DesktopContainer;
