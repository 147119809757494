import * as React from "react";

import * as sc from "./Dropdown.sc";

export interface IProps {
    children: React.ReactNode;
    header: React.ReactNode;
    open: boolean;
    onHeaderClick: () => void;
    maxHeight?: string;
    widthBody?: string;
    alignRightBody?: boolean;
    hasNoIcon?: boolean;
    disableMargin?: boolean;
    id?: string;
    alignItems?: "center" | "baseline";
}

const Dropdown: React.FC<IProps> = ({ header, children, open, onHeaderClick, hasNoIcon, disableMargin, id, alignItems }) => {
    return (
        <sc.Root hasNoIcon={hasNoIcon} id={id}>
            <sc.Header onClick={onHeaderClick} disableMargin={disableMargin} alignItems={alignItems}>
                {header}
                {!hasNoIcon && <sc.ChevronDown open={open} />}
            </sc.Header>
            <sc.Collapseable height={open ? `auto` : 0} duration={500}>
                <div>{children}</div>
            </sc.Collapseable>
        </sc.Root>
    );
};

export default Dropdown;
