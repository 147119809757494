import { useLocation } from "@reach/router";
import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { getDefaultFilter } from "@src/shared/context/searchfilter/IFilter";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import React from "react";
import { FormattedMessage } from "react-intl";

import { generateVehicleDetailUrl } from "../generateVehicleDetailUrl";
import { findNextVehicle, findPreviousVehicle } from "../VehicleDetailHelper";
import * as sc from "./Header.sc";

const defaultDownPayment = getDefaultFilter(getConfig("scope").domain).downPayment;

interface HeaderProps {
    trackingEvent: "leaseme-offer-close" | "car4me-offer-close";
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const Header: React.FC<HeaderProps> = ({ trackingEvent, vehicle }) => {
    const { filter, generateUrlFromFilter } = useSearchFilter();
    const { vehicles } = useSearchResult();
    const location = useLocation();

    // The vehicles in the search result change when the user enters new financing related values in the lightbox. This would break the navigation
    // between the vehicle detail pages of a search result. We therefore keep a copy of the intial vehicles to ensure that the navigation is correct.
    const initialVehicles = React.useRef(vehicles);
    const previousVehicle = findPreviousVehicle(vehicle, initialVehicles.current);
    const nextVehicle = findNextVehicle(vehicle, initialVehicles.current);

    const handleBackClick = () => {
        pushToDataLayer({
            event: trackingEvent,
            financingPayment: null,
            carCode8: null,
            carBrand: null,
            carModel: null,
            carModelGroup: null,
            carModelPriceNet: null,
            carCategory: null,
            carMileage: null,
            carRegistration: null,
            dealerName: null,
            dealerId: null,
            dealerPostcode: null,
            dealerLocation: null,
        });
    };

    return (
        <sc.HeaderSection>
            <sc.StyledLinkDesktop to={generateUrlFromFilter({ ...filter, downPayment: defaultDownPayment }, "vehicles")} onClick={handleBackClick}>
                <sc.ChevronLeft />
                <FormattedMessage id="leaseme.searchResult" defaultMessage="Suchergebnis" />
            </sc.StyledLinkDesktop>
            <sc.StyledLinkMobile to={generateUrlFromFilter({ ...filter, downPayment: defaultDownPayment }, "vehicles")} onClick={handleBackClick}>
                <sc.ChevronLeft />
                <FormattedMessage id="leaseme.searchResult.back" defaultMessage="zurück" />
            </sc.StyledLinkMobile>
            <sc.LastNextContainer>
                {previousVehicle && (
                    <sc.StyledLink to={generateVehicleDetailUrl(previousVehicle, { ...filter, downPayment: defaultDownPayment }, location)}>
                        <sc.PrevVehicle>
                            <sc.ChevronLeft />
                            <FormattedMessage
                                id="leaseme.prevVehicle"
                                defaultMessage="vorheriges <span>Fahrzeug</span>"
                                values={{
                                    // eslint-disable-next-line react/display-name
                                    span: (chunks: any) => <sc.LinkVehicle>{chunks}</sc.LinkVehicle>,
                                }}
                            />
                        </sc.PrevVehicle>
                    </sc.StyledLink>
                )}
                {nextVehicle && (
                    <sc.StyledLink to={generateVehicleDetailUrl(nextVehicle, { ...filter, downPayment: defaultDownPayment }, location)}>
                        <sc.NextVehicle>
                            <FormattedMessage
                                id="leaseme.nextVehicle"
                                defaultMessage="nächstes <span>Fahrzeug</span>"
                                values={{
                                    // eslint-disable-next-line react/display-name
                                    span: (chunks: any) => <sc.LinkVehicle>{chunks}</sc.LinkVehicle>,
                                }}
                            />
                            <sc.ChevronRight />
                        </sc.NextVehicle>
                    </sc.StyledLink>
                )}
            </sc.LastNextContainer>
        </sc.HeaderSection>
    );
};

export default Header;
