import { Text, View } from "@react-pdf/renderer";
import { getConfig } from "@src/config";
import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import * as React from "react";
import { useIntl } from "react-intl";

import { finCalcEntryStyles } from "./FinCalcEntryStyles";

interface IProps {
    item: GQLFinanceInformationParameter;
}

const FinCalcItem: React.FunctionComponent<IProps> = ({ item }) => {
    const intl = useIntl();

    return (
        <View style={finCalcEntryStyles.root}>
            <View style={finCalcEntryStyles.titleContainer}>
                <Text style={item.bold ? finCalcEntryStyles.boldvalue : finCalcEntryStyles.usualvalue}>{item.label}</Text>
            </View>
            <Text style={item.bold ? finCalcEntryStyles.boldvalue : finCalcEntryStyles.usualvalue}>
                {[!isNaN(Number(item.value)) ? intl.formatNumber(Number(item.value)) : item.value, " ", item.unit]}
                {getConfig("scope").domain === "car4me.porschefinance.hu" && item.id === "Rate" && "** "}
            </Text>
        </View>
    );
};

export default FinCalcItem;
