import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const finCalcEntryStyles = StyleSheet.create({
    root: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "4pt",
        paddingBottom: "4pt",
    },
    titleContainer: {
        flexDirection: "row",
        paddingRight: "15pt",
        width: "50%",
    },
    usualvalue: {
        fontSize: "7pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
    },
    boldvalue: {
        fontSize: "7pt",
        fontFamily: "MarkW1G",
        fontWeight: "bold",
        color: theme.colors.porscheBankBlue,
        textOverflow: undefined,
    },
});
