import { gql } from "@apollo/client";
import { VehicleDetailDealerInfo } from "@src/shared/vehicleDetail/dealer/DealerInfo.gql";

export const PriceInfoFragment = gql`
    fragment PriceInfo on RawVehicle {
        finCalcData(
            financeType: $financeType
            financeRuntime: $financeRuntime
            downPayment: $downPayment
            yearlyMileage: $yearlyMileage
            residualCreditInPercent: $residualCreditInPercent
        ) {
            downPayment
            disclaimer
            residualValue
            rate
            processingCostAmount
            interestRate
            effectivRate
            sumAllPayments
            financingSum
        }
    }
`;

export const VehicleDetailQuery = gql`
    query VehicleDetail(
        $vehicleId: String!
        $financeType: FinanceType
        $financeRuntime: Int!
        $downPayment: Float!
        $yearlyMileage: Int!
        $leasemeScope: ContentScopeInput!
        $residualCreditInPercent: Int
    ) {
        vehicle(id: $vehicleId, scope: $leasemeScope) {
            vehicleId
            type
            externalID
            equipment {
                text
            }
            nwSeriesEquipment {
                text
            }
            vehicleLink
            offerReference
            brand
            modelGroup
            model
            modelCode
            modelVariant
            eurotaxCode
            bodyColor {
                text
            }
            firstRegistration
            powerPS
            powerKW
            mileage
            driveType {
                value
                fmt
            }
            doors
            seats
            vatDeductible
            previousOwners
            selfWeight
            totalWeight
            garantie
            extraInfo
            vin
            images {
                uri
            }
            category
            serviceBook
            netListPrice
            importDatum
            isAuction
            timestamp
            lkw
            fuel {
                co2 {
                    fmt
                }
                consumption {
                    fmt
                }
                text
            }
            bodyType {
                fmt
            }
            cylinderCapacity {
                fmt
            }
            transmission {
                fmt
            }
            dealer {
                ...VehicleDetailDealerInfo
            }
            #TODO hier gehört noch was geändert
            leasingRate: rate(
                financeType: leasing
                financeRuntime: $financeRuntime
                downPayment: $downPayment
                yearlyMileage: $yearlyMileage
                residualCreditInPercent: $residualCreditInPercent
            )
            leasingDownPayment: downPayment(
                financeType: leasing
                financeRuntime: $financeRuntime
                downPayment: $downPayment
                yearlyMileage: $yearlyMileage
                residualCreditInPercent: $residualCreditInPercent
            )
            creditRate: rate(
                financeType: credit
                financeRuntime: $financeRuntime
                downPayment: $downPayment
                yearlyMileage: $yearlyMileage
                residualCreditInPercent: $residualCreditInPercent
            )
            creditDownPayment: downPayment(
                financeType: credit
                financeRuntime: $financeRuntime
                downPayment: $downPayment
                yearlyMileage: $yearlyMileage
                residualCreditInPercent: $residualCreditInPercent
            )
            ...PriceInfo
        }
    }

    ${PriceInfoFragment}
    ${VehicleDetailDealerInfo}
`;
