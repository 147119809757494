import { StyleSheet } from "@react-pdf/renderer";

export const leasemePdfStyles = StyleSheet.create({
    root: {
        flexDirection: "column",
        margin: "0 30pt",
    },
    page: {
        paddingBottom: "2cm",
    },
});
