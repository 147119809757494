import { gql, useQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import PriceInfoValuesAT from "@src/AT/vehicleDetail/priceInfoSection/PriceInfoValues";
import PriceInfoValuesBG from "@src/BG/vehicleDetail/priceInfoSection/PriceInfoValues";
import { getConfig } from "@src/config";
import { GQLFinanceType, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import PriceInfoValuesHU from "@src/HU/vehicleDetail/priceInfoSection/PriceInfoValues";
import PriceInfoValuesRO from "@src/RO/vehicleDetail/priceInfoSection/PriceInfoValues";
import useFinCalcData from "@src/shared/context/fincalc/useFinCalcData";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import { SnackbarType } from "@src/shared/context/snackbar/SnackbarContext";
import useSnackbar from "@src/shared/context/snackbar/useSnackbar";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import getVehicleDataBlob from "@src/shared/utils/getVehicleDetailBlob";
import ContactForm from "@src/shared/vehicleDetail/contactForm/ContactForm";
import { generateVehicleDetailUrl } from "@src/shared/vehicleDetail/generateVehicleDetailUrl";
import { ImageSwiper } from "@src/shared/vehicleDetail/imageSwiper/ImageSwiper";
import PriceInfoSection from "@src/shared/vehicleDetail/priceInfoSection/PriceInfoSection";
import ShareMenu from "@src/shared/vehicleDetail/shareMenu/ShareMenu";
import PriceInfoValuesSI from "@src/SI/vehicleDetail/priceInfoSection/PriceInfoValues";
import { DealerButton } from "@src/skeleton/vehicleDetail/imageContentSection/DealerButton";
import { OnlineClosureButton } from "@src/skeleton/vehicleDetail/imageContentSection/OnlineClosureButton";
import { saveAs } from "file-saver";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./ImageContentSection.sc";

interface ImageContentSectionProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    shareMenuItems: React.ReactElement;
}

const PriceInfoValues: React.FC<{
    visibleTab: GQLFinanceType;
    vehicle: GQLVehicleDetailQuery["vehicle"];
}> = ({ vehicle, visibleTab }) => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return <PriceInfoValuesBG data={vehicle} />;
        case "car4me.ro":
            return <PriceInfoValuesRO data={vehicle} />;
        case "car4me.si":
            return <PriceInfoValuesSI data={vehicle} visibleTab={visibleTab} />;
        case "car4me.porschefinance.hu":
            return <PriceInfoValuesHU data={vehicle} visibleTab={visibleTab} />;
        default:
            return <PriceInfoValuesAT data={vehicle} visibleTab={visibleTab} />;
    }
};

const ImageContentSection: React.FunctionComponent<ImageContentSectionProps> = ({ vehicle, shareMenuItems }) => {
    const leasingCapable = vehicle.leasingRate !== null;
    const [contactFormOpen, setContactFormOpen] = React.useState(false);
    const [onlineClosureFormOpen, setOnlineClosureFormOpen] = React.useState(false);
    const [shareMenuOpen, setShareMenuOpen] = React.useState(false);

    const [isPdfDownloading, setIsPdfDownloading] = React.useState(false);

    const { financeInformation } = useFinCalcData();

    const { showSnackbar } = useSnackbar();
    const contactRef = React.useRef<Element>();
    const onlineClosureRef = React.useRef<Element>();

    const { filter, setFilter } = useSearchFilter();
    const intl = useIntl();
    const location = useLocation();

    const [visibleTab, setVisibleTab] = React.useState<GQLFinanceType>(filter.financeType);

    //TODO remove visibleTab if there is enough time to do it
    React.useEffect(() => {
        setVisibleTab(filter.financeType);
    }, [filter.financeType]);

    const { data } = useQuery<{
        cascoFile: {
            id: string;
            file: { id: string; fileUrl: string };
        } | null;
    }>(cascoFileQuery, {
        variables: { scope: getConfig("scope") },
        context: { comet: true },
    });
    const cascoFileUrl = data?.cascoFile?.file.fileUrl ?? null;

    const handleCascoFileDownloadClick = () => {
        if (cascoFileUrl === null) {
            return;
        }

        saveAs(
            cascoFileUrl,
            intl.formatMessage({
                id: "leaseme.vehicleDetails.cascoFile.fileName",
                defaultMessage: "Casco PID.pdf",
            }),
        );
    };

    const handleContactButtonClick = () => {
        setContactFormOpen(true);
        setOnlineClosureFormOpen(false);

        pushToDataLayer({
            event: "dealer-contact-form-open",
        });
    };

    const handleOnlineClosureButtonClick = () => {
        setOnlineClosureFormOpen(true);
        setContactFormOpen(false);

        pushToDataLayer({
            event: "online-closure-form-open",
        });
    };

    const onDownloadButtonClicked = async (type: GQLFinanceType) => {
        setIsPdfDownloading(true);
        try {
            const blob = await getVehicleDataBlob(vehicle, type, filter, financeInformation);
            saveAs(
                blob,
                intl.formatMessage({
                    id: "leaseme.vehicleDetails",
                    defaultMessage: "Fahrzeugdetails",
                }) + ".pdf",
            );
        } catch (error) {
            showSnackbar(
                SnackbarType.error,
                intl.formatMessage({
                    id: "leaseme.snackbar.pdf.error.",
                    defaultMessage: "Fehler! Das PDF konnte nicht heruntergeladen werden!",
                }),
            );
        }

        setIsPdfDownloading(false);
    };

    const handleContactFormClose = () => {
        setContactFormOpen(false);
    };

    const handleOnlineClosureFormClose = () => {
        setOnlineClosureFormOpen(false);
    };

    function handleShareButtonClick() {
        setShareMenuOpen(true);
    }

    function handleShareMenuClose() {
        setShareMenuOpen(false);
    }

    const priceInfoDisclaimer = vehicle.finCalcData?.disclaimer;

    const onChangeFinanceType = (newFinanceType: GQLFinanceType) => {
        setVisibleTab(newFinanceType);
        if (setFilter) setFilter("financeType", newFinanceType, false);
    };

    return (
        <>
            {/* For the purpose of debugging PDFs I would like to leave this in */}
            {/* <PDFViewer style={{ width: "100%", height: "800px" }}>
                <CustomIntlProvider>
                    <LeaseMePdf
                        vehicle={vehicle}
                        type={GQLFinanceType.leasing}
                        filter={filter}
                        financeInformation={financeInformation}
                    />
                </CustomIntlProvider>
            </PDFViewer> */}

            <sc.ImageContentSection>
                <sc.ImageContainer>
                    <sc.Images>
                        {/* Key is needed to re-create ImageSlider when navigating between detail pages */}
                        <ImageSwiper key={vehicle.vehicleId!} images={vehicle.images} fullWidth />
                    </sc.Images>
                    <sc.CarDetailHeadlineMobile>
                        <sc.ShareButtonMobileContainer>
                            <sc.StyledShareIcon onClick={handleShareButtonClick} />
                            <ShareMenu shareMenuOpen={shareMenuOpen} handleShareMenuClose={handleShareMenuClose} shareMenuItems={shareMenuItems} />
                        </sc.ShareButtonMobileContainer>
                        <sc.HorizontalLine />
                        <sc.CarTitle>{vehicle.model} *</sc.CarTitle>
                    </sc.CarDetailHeadlineMobile>
                </sc.ImageContainer>

                <sc.ContentContainer>
                    <PriceInfoSection
                        setVisibleTab={(tab) => onChangeFinanceType(tab)}
                        visibleTab={visibleTab}
                        leasingCapable={leasingCapable}
                        disclaimer={priceInfoDisclaimer}
                        disclaimerAsterisk={
                            getConfig("scope").domain === "lease-me.porschebank.at" || getConfig("scope").domain === "car4me.porschefinance.hu"
                                ? "**"
                                : "*"
                        }
                    >
                        <PriceInfoValues vehicle={vehicle} visibleTab={visibleTab} />
                        {getConfig("scope").domain === "lease-me.porschebank.at" && (
                            <sc.GeolocationWrapper>
                                <sc.Geolocation>
                                    <sc.GeolocationIcon /> {vehicle.dealer?.zip} {vehicle.dealer?.city}
                                </sc.Geolocation>
                            </sc.GeolocationWrapper>
                        )}
                    </PriceInfoSection>

                    <sc.DealerClosureButtonsWrapper>
                        <DealerButton vehicle={vehicle} onClick={handleContactButtonClick} />
                        {getConfig("scope").domain === "lease-me.porschebank.at" && <OnlineClosureButton onClick={handleOnlineClosureButtonClick} />}
                    </sc.DealerClosureButtonsWrapper>
                    <sc.ResidualLinks>
                        <sc.PDFLink onClick={() => onDownloadButtonClicked(visibleTab)} disabled={isPdfDownloading} data-gtm-event="offer-download">
                            <sc.DownloadIcon />
                            <FormattedMessage id="leaseme.vehicleItem.download" defaultMessage="PDF herunterladen" />
                            {isPdfDownloading && <sc.Loading />}
                        </sc.PDFLink>
                        {cascoFileUrl && getConfig("hasCascoPid") && (
                            <sc.PDFLink onClick={handleCascoFileDownloadClick}>
                                <sc.DownloadIcon />
                                <FormattedMessage id="leaseme.vehicleItem.download.cascoFile" defaultMessage="Casco PID herunterladen" />
                            </sc.PDFLink>
                        )}
                        {getConfig("scope").domain !== "lease-me.porschebank.at" && (
                            <sc.CarDataLink to={`${generateVehicleDetailUrl(vehicle, filter, location)}#vehicleData`}>
                                <sc.ChevronIcon />
                                <FormattedMessage id="leaseme.vehicleData" defaultMessage="Fahrzeugdaten" />
                            </sc.CarDataLink>
                        )}
                        {getConfig("scope").domain === "lease-me.porschebank.at" && (
                            <sc.FinCalculator to={`${generateVehicleDetailUrl(vehicle, filter, location)}#lightboxDesktop`}>
                                <sc.ChevronIcon />
                                <FormattedMessage id="leaseme.financing.calculator" defaultMessage="Finanzierungsrechner" />
                            </sc.FinCalculator>
                        )}

                        {getConfig("scope").domain === "lease-me.porschebank.at" && (
                            <sc.QuickCheckLink to={`${generateVehicleDetailUrl(vehicle, filter, location)}#boni`}>
                                <sc.ChevronIcon />
                                <FormattedMessage id="leaseme.boniCheck" defaultMessage="Bonitäts-QuickCheck" />
                            </sc.QuickCheckLink>
                        )}
                    </sc.ResidualLinks>
                </sc.ContentContainer>
            </sc.ImageContentSection>
            {contactFormOpen && <ContactForm handleClose={handleContactFormClose} vehicle={vehicle} visibleTab={visibleTab} refProp={contactRef} />}
            {getConfig("scope").domain === "lease-me.porschebank.at" && onlineClosureFormOpen && (
                <ContactForm
                    handleClose={handleOnlineClosureFormClose}
                    vehicle={vehicle}
                    visibleTab={visibleTab}
                    refProp={onlineClosureRef}
                    type="ONLINE_CLOSURE"
                />
            )}
        </>
    );
};

export default ImageContentSection;

const cascoFileQuery = gql`
    query CascoFile($scope: ContentScopeInput!) {
        cascoFile(scope: $scope) {
            id
            file {
                id
                fileUrl
            }
        }
    }
`;
