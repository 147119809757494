import { View } from "@react-pdf/renderer";
import { GQLFinanceType, GQLPriceInfoFragment } from "@src/graphql.apollo.generated";
import { IFilter } from "@src/shared/context/searchfilter/IFilter";
import { getFormattedCurrency } from "@src/shared/utils/getFormattedCurrency";
import FinancialDetailDataEntryComponent from "@src/shared/vehicleDetail/pdf/financialdetail/FinancialDetailDataEntryComponent";
import * as React from "react";
import { useIntl } from "react-intl";

import { financialDetailDataStyles } from "./FinancialDetailDataStyles";

interface IDetailsInfoProps {
    type: GQLFinanceType;
    priceInfo?: GQLPriceInfoFragment;
    filter: IFilter;
}

const FinancialDetailDataComponent: React.FunctionComponent<IDetailsInfoProps> = ({ type, priceInfo, filter }) => {
    const intl = useIntl();

    const downPayment = getFormattedCurrency({ value: priceInfo?.finCalcData?.downPayment || 0 }, intl);

    const durationValue = intl.formatMessage(
        {
            id: "leaseme.runtime.value",
            defaultMessage: "{financeRuntime} Monate",
        },
        {
            financeRuntime: intl.formatNumber(filter.financeRuntime),
        },
    );
    const distanceValue = `${intl.formatNumber(filter.kmPerYear)} ${intl.formatMessage({
        id: "leaseme.km",
        defaultMessage: "km",
    })}`;
    const residualValue = getFormattedCurrency(
        {
            value: priceInfo?.finCalcData?.residualValue || 0,
        },
        intl,
    );

    return (
        <View style={financialDetailDataStyles.infoContainer}>
            <FinancialDetailDataEntryComponent
                value={durationValue}
                description={intl.formatMessage({
                    id: "leaseme.runtime.label",
                    defaultMessage: "Laufzeit",
                })}
            />
            {type === GQLFinanceType.leasing && (
                <FinancialDetailDataEntryComponent
                    value={distanceValue}
                    description={intl.formatMessage({
                        id: "leaseme.kmPerYear.long.label",
                        defaultMessage: "Kilometer/Jahr",
                    })}
                />
            )}
            {type === GQLFinanceType.credit && (
                <FinancialDetailDataEntryComponent
                    value={residualValue}
                    description={`${intl.formatMessage({
                        id: "leaseme.remainingCredit.label",
                        defaultMessage: "Restkredit",
                    })} (${filter.remainingCredit}%)`}
                />
            )}

            <FinancialDetailDataEntryComponent
                value={downPayment}
                description={intl.formatMessage({
                    id: "leaseme.downPayment.label",
                    defaultMessage: "Eigenleistung",
                })}
            />
        </View>
    );
};

export default FinancialDetailDataComponent;
