import SharedHeader from "@src/shared/vehicleDetail/pdf/header/Header";
import * as React from "react";

import Logo_at from "../../../../../static/at/pdfLogoPorschebank.png";

const Header: React.FC = () => {
    return <SharedHeader logo={Logo_at} />;
};

export default Header;
