import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import * as React from "react";

import FinanceTypeSwitch from "../financeTypeSwitch/FinanceTypeSwitch";
import * as sc from "./PriceInfoSection.sc";

interface IPriceInfoSectionProps {
    setVisibleTab: (type: GQLFinanceType) => void;
    visibleTab: GQLFinanceType;
    disclaimer: React.ReactNode;
    leasingCapable: boolean;
    disclaimerAsterisk?: string;
}

const PriceInfoSection: React.FunctionComponent<IPriceInfoSectionProps> = ({
    setVisibleTab,
    visibleTab,
    disclaimer,
    leasingCapable,
    children,
    disclaimerAsterisk = "",
}) => {
    const { setFilter } = useSearchFilter();

    const setNewFinanceType = (newFinanceType: GQLFinanceType) => {
        setVisibleTab(newFinanceType);
        setFilter && setFilter("financeType", newFinanceType, false);
    };

    return (
        <>
            <FinanceTypeSwitch
                financeType={visibleTab}
                setFinanceType={(newFinanceType) => setNewFinanceType(newFinanceType)}
                leasingCapable={leasingCapable}
            />
            <sc.Content>
                {children}
                {getConfig("scope").domain !== "lease-me.porschebank.at" && (
                    <sc.ResidualText>
                        {disclaimer && disclaimerAsterisk}
                        {disclaimer}
                    </sc.ResidualText>
                )}
            </sc.Content>
        </>
    );
};

export default PriceInfoSection;
