import styled from "styled-components";

export const Section = styled.section`
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    margin-bottom: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        border-bottom-color: ${({ theme }) => theme.colors.backgroundLightGray};
        margin-bottom: 40px;
    }
`;

export const SubHeadline = styled.h2`
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    flex-basis: 100%;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-top: 40px;
        margin-bottom: 20px;
        flex-basis: 50%;
    }
`;

export const Disclaimer = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 20px 0;
    hyphens: auto;

    a {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        transition: all 0.25s ease-in-out;
        font-weight: 700;
        text-decoration: none;
    }

    a:hover {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 16px;
    }
`;

export const DropdownHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
        gap: 10px;
    }
`;

export const LabelWrapper = styled.div`
    width: 100%;
    display: block;
    margin-top: -10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
        margin-top: 0;
    }
`;
