import { gql } from "@apollo/client";

export const FinanceInformationErrorFragment = gql`
    fragment FinanceInformationErrorFragment on FinanceInformationError {
        type
        group
        field
        description
        id
    }
`;

export const FinanceInformationParameterFragment = gql`
    fragment FinanceInformationParameterFragment on FinanceInformationParameter {
        id
        label
        type
        selectType
        dataType
        unit
        value
        options {
            label
            value
        }
        defaultOption
        help
        bold
        length
        error {
            ...FinanceInformationErrorFragment
        }
    }
    ${FinanceInformationErrorFragment}
`;

export const FinanceInformationGroupFragment = gql`
    fragment FinanceInformationGroupFragment on FinanceInformationGroup {
        label
        disclaimer
        parameters {
            ...FinanceInformationParameterFragment
        }
    }
    ${FinanceInformationParameterFragment}
`;

export const financeInformationQuery = gql`
    query FinanceInformation($id: String!, $leasemeScope: ContentScopeInput!, $args: FinanceCalculationInput!, $financeType: FinanceType) {
        vehicle(scope: $leasemeScope, id: $id) {
            vehicleId
            financeInformation(financeType: $financeType, args: $args) {
                productDisclaimer
                globalDisclaimer
                groups {
                    ...FinanceInformationGroupFragment
                }
                errors {
                    ...FinanceInformationErrorFragment
                }
            }
        }
    }
    ${FinanceInformationGroupFragment}
    ${FinanceInformationErrorFragment}
`;
