import { Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { vehicleDataEntryStyles } from "./VehicleDataEntryStyles";
interface IDataEntryProps {
    title: React.ReactNode;
    value?: string | null;
}

const VehicleDataEntryComponent: React.FunctionComponent<IDataEntryProps> = ({ title, value = "-" }) => {
    return (
        <View style={vehicleDataEntryStyles.root}>
            <View style={vehicleDataEntryStyles.titleContainer}>
                <Text style={vehicleDataEntryStyles.title}>{title}</Text>
            </View>
            <Text style={vehicleDataEntryStyles.value}>{value}</Text>
        </View>
    );
};

export default VehicleDataEntryComponent;
