import { GQLVehicleDetailQuery, GQLVehicleSearchResultVehicleFragment } from "@src/graphql.apollo.generated";

export const findPreviousVehicle = (
    targetVehicle: GQLVehicleDetailQuery["vehicle"] | null,
    vehicles: GQLVehicleSearchResultVehicleFragment[] | undefined,
) => {
    if (!targetVehicle || !vehicles) {
        return undefined;
    }

    const targetVehicleIndex = vehicles.findIndex((vehicle) => vehicle.vehicleId === targetVehicle.vehicleId);

    if (targetVehicleIndex === -1 || targetVehicleIndex === 0) {
        return undefined;
    }

    return vehicles[targetVehicleIndex - 1];
};

export const findNextVehicle = (
    targetVehicle: GQLVehicleDetailQuery["vehicle"] | null,
    vehicles: GQLVehicleSearchResultVehicleFragment[] | undefined,
) => {
    if (!targetVehicle || !vehicles) {
        return undefined;
    }

    const targetVehicleIndex = vehicles.findIndex((vehicle) => vehicle.vehicleId === targetVehicle.vehicleId);

    if (targetVehicleIndex === -1 || targetVehicleIndex === vehicles.length - 1) {
        return undefined;
    }

    return vehicles[targetVehicleIndex + 1];
};
