import { GQLBoniCheckParameterFragment } from "@src/graphql.apollo.generated";
import { IResponseGroupParameter } from "@src/shared/utils/httpClient";
import * as React from "react";

import DateSelect from "../FormFields/DateSelect";
import Hidden from "../FormFields/Hidden";
import Input from "../FormFields/Input";
import Select from "../FormFields/Select";
import ShowField from "../FormFields/ShowField";
import * as sc from "./Row.sc";

export interface IRowProps {
    parameter: IResponseGroupParameter;
    errors?: GQLBoniCheckParameterFragment["errors"] | null;
    updateFinCalcFormValue?: (key: string, value: any) => void;
}

const Row: React.FunctionComponent<IRowProps> = ({ parameter, errors, updateFinCalcFormValue }) => {
    const getFieldError = () => {
        if (errors) {
            for (const error of errors) {
                if (error.field === parameter.id || (error.field === "RemainingInstallmentInclVAT" && parameter.name === "Rate")) {
                    return error.description;
                }
            }
        }
    };

    parameter.data.forEach((parameter) => {
        switch (parameter.value) {
            case "true":
                parameter.value = true;
                break;
            case "false":
                parameter.value = false;
                break;
            default:
                break;
        }
        switch (parameter.valueFromAttribute) {
            case "true":
                parameter.valueFromAttribute = true;
                break;
            case "false":
                parameter.valueFromAttribute = false;
                break;
            default:
                break;
        }
    });

    const getFormField = () => {
        const style = parameter.control.style;

        switch (parameter.control.type) {
            case "Select":
                if (parameter.id === "durationOfEmploymentInYears") {
                    parameter.data = parameter.data.map((option) => {
                        return {
                            ...option,
                            displayValue: option.value === "<1" ? option.value + " Jahr" : option.value + " Jahre(n)",
                        };
                    });
                }

                return {
                    style,
                    formField: <Select name={parameter.id} style={style} options={parameter.data} label={parameter.label} />,
                    displayField: <ShowField name={parameter.id} value={parameter.note ? parameter.note : parameter.data[0].value} />,
                };
            case "Label":
                return {
                    style,
                    formField: (
                        <ShowField
                            name={parameter.id}
                            value={parameter.data[0].value}
                            valueFromAttribute={parameter.data[0].valueFromAttribute}
                            errorMessage={getFieldError()}
                        />
                    ),
                };
            case "Edit":
                return {
                    style,
                    formField: (
                        <Input
                            name={parameter.id}
                            type={parameter.control.dataType === "Decimal" ? "number" : "text"}
                            maxLength={parameter.control.length ? Number(parameter.control.length) : 100}
                            value={parameter.data ? parameter.data[0].valueFromAttribute : parameter.value}
                            errorMessage={getFieldError()}
                            label={parameter.label}
                            startAdornment={parameter.units}
                            help={
                                parameter.help ? (
                                    <sc.ToolTip>
                                        <sc.IconWrapper>
                                            <sc.InnerToolTip>
                                                <sc.ToolTipText
                                                    className="kwfLocal__toolTipText"
                                                    dangerouslySetInnerHTML={{
                                                        __html: parameter.help || "",
                                                    }}
                                                />
                                            </sc.InnerToolTip>
                                        </sc.IconWrapper>
                                    </sc.ToolTip>
                                ) : undefined
                            }
                        />
                    ),
                };
            case "Hidden":
                return {
                    style,
                    formField: (
                        <Hidden name={parameter.id} value={parameter.data[0].valueFromAttribute} updateFinCalcFormValue={updateFinCalcFormValue} />
                    ),
                };
            case "Date":
                return {
                    style,
                    formField: <DateSelect name={parameter.id} value={parameter.data[0].valueFromAttribute} />,
                };
            default:
                return {};
        }
    };

    const formField = getFormField();
    return (
        <sc.FormRowWrapper parameter={parameter}>
            <sc.InputWrapper parameter={parameter}>
                {formField.style === "List" && formField.formField}
                {!!parameter.label &&
                    formField.style !== "List" &&
                    (parameter.label === "Seit:" ? "Ich bin in einem aufrechten Dienstverhältnis seit:" : parameter.label)}
            </sc.InputWrapper>

            <sc.Value>
                <div>{formField.style !== "List" && formField.style !== "Radio" && formField.formField}</div>
                <div>{formField.style === "List" && formField.displayField}</div>
            </sc.Value>
            {getFieldError() && <sc.Error>{getFieldError()}</sc.Error>}
        </sc.FormRowWrapper>
    );
};

export default Row;
