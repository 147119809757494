import MarkW1GHeavy from "./MarkW1G-Heavy.ttf";
import MarkW1GMedium from "./MarkW1G-Medium.ttf";
import MarkW1GRegular from "./MarkW1G-Regular.ttf";

const Fonts = {
    MarkW1GRegular,
    MarkW1GMedium,
    MarkW1GHeavy,
};

export default Fonts;
