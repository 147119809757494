import { useLazyQuery, useQuery } from "@apollo/client";
import { getConfig } from "@src/config";
import {
    GQLBoniCheckParameterFragment,
    GQLBoniCheckQuery,
    GQLBoniCheckQueryVariables,
    GQLBoniCheckUpdateQuery,
    GQLBoniCheckUpdateQueryVariables,
    GQLFinanceType,
} from "@src/graphql.apollo.generated";
import { SearchFilterContext } from "@src/shared/context/searchfilter/SearchFilterContext";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import { FormState } from "final-form";
import * as React from "react";

import { boniCheckQuery, boniCheckUpdateQuery } from "./BoniCheckMaster.gql";
import * as sc from "./BoniCheckMaster.sc";
import BoniCheckForm from "./Form/BoniCheckForm";

interface IBoniCheckMasterProps {
    vehicleId: string;
}

const BoniCheckMaster: React.FunctionComponent<IBoniCheckMasterProps> = ({ vehicleId }) => {
    const { filter } = React.useContext(SearchFilterContext);

    const [boniCheckParameters, setBoniCheckParameters] = React.useState<GQLBoniCheckParameterFragment | null>();
    const [activeTab, setActiveTab] = React.useState<GQLFinanceType>(filter.financeType);
    const [showBoniCheckResult, setShowBoniCheckResult] = React.useState<boolean>(false);

    const { data, loading } = useQuery<GQLBoniCheckQuery, GQLBoniCheckQueryVariables>(boniCheckQuery, {
        variables: {
            id: vehicleId,
            leasemeScope: getConfig("scope"),
        },
        fetchPolicy: "no-cache",
    });

    const [updateBoniCheckQuery, { loading: isLoadingBoniCheck }] = useLazyQuery<GQLBoniCheckUpdateQuery>(boniCheckUpdateQuery, {
        onCompleted: (data) => {
            setBoniCheckParameters(data?.checkIfBoniIsAvailable);
        },
    });

    function setDefaultState() {
        setBoniCheckParameters(undefined);
        setActiveTab(activeTab ? activeTab : GQLFinanceType.leasing);
        setShowBoniCheckResult(false);
    }

    const updateBoniCheckParametersFunction = (values: any): void => {
        const finCalcData =
            activeTab === GQLFinanceType.leasing ? data?.FinCalcProducts.finCalcProductsLeasing : data?.FinCalcProducts.finCalcProductsCredit;

        const requestParams: GQLBoniCheckUpdateQueryVariables = {
            vehicleSearchData: {
                downPayment: filter.downPayment,
                duration: filter.financeRuntime,
                annualMileage: filter.kmPerYear,
            },
            financeData: {
                rate: finCalcData?.rate ? finCalcData?.rate : 0,
                cashBenefit: finCalcData?.cashBenefit ? finCalcData?.cashBenefit : 0,
            },
            personalData: {
                monthlyFixedCosts: values.monthlyFixedCosts ? Number(values.monthlyFixedCosts) : 0,
                monthlyIncome: values.monthlyIncome ? Number(values.monthlyIncome) : 0,
                dateOfBirth: values.dateOfBirth,
                austrianNationals: values.austrianNationals,
                paymentObligationsProperly: values.paymentObligationsProperly,
                employmentRelationship: values.employmentRelationship,
                durationOfEmploymentInYears: values.durationOfEmploymentInYears,
            },
            vehicleId,
            leasemeScope: getConfig("scope"),
        };

        updateBoniCheckQuery({
            variables: {
                ...requestParams,
            },
        });
    };

    const onBoniCheckChangeFunction = (formState: FormState<any>) => {
        if (!formState.valid) return;
        for (const fieldName in formState.modified) {
            if (formState.modified[fieldName]) {
                updateBoniCheckParametersFunction({
                    ...formState.values,
                });
                setShowBoniCheckResult(false);
                break;
            }
        }
    };

    const onBoniCheckSubmitFunction = (values: FormData) => {
        pushToDataLayer({
            event: "creditworthiness-submit",
        });

        updateBoniCheckParametersFunction({
            ...values,
        });
        setShowBoniCheckResult(true);
    };

    if (loading) {
        return <sc.Loading />;
    }

    return (
        <>
            {(boniCheckParameters || data?.BoniCheckParameter) && data?.FinCalcProducts && (
                <BoniCheckForm
                    parameters={boniCheckParameters ? boniCheckParameters : data?.BoniCheckParameter}
                    isLoading={isLoadingBoniCheck}
                    showResult={showBoniCheckResult}
                    onChange={onBoniCheckChangeFunction}
                    onSubmit={onBoniCheckSubmitFunction}
                    reset={setDefaultState}
                />
            )}
        </>
    );
};

export default BoniCheckMaster;
