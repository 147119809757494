import * as ChevronDown from "@src/shared/assets/images/chevron_down.inline.svg";
import styled from "styled-components";

export const EquipmentContainer = styled.div``;

export const EquipmentHeadline = styled.div`
    font-size: 23px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin-bottom: 10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-bottom: 20px;
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        font-size: 20px;
        line-height: 30px;
        margin-top: 40px;
    }
`;

export const HeadlineText = styled.h1`
    display: none;

    margin-bottom: 0;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: block;
        font-size: 32px;
        line-height: 40px;
    }
`;

export const HorizontalLine = styled.div`
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
        border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
        width: 76px;
    }
`;

interface IOpacityBoxProps {
    showMore: boolean;
}

export const OpacityBox = styled.div<IOpacityBoxProps>`
    position: relative;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        height: ${({ showMore }) => (showMore ? "initial" : "300px")};
        overflow: hidden;

        :after {
            display: ${({ showMore }) => (showMore ? "none" : "block")};
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 75%, white 100%);
        }
    }
`;

export const CarDataList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const CarDataListItem = styled.li`
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;

    :before {
        display: inline-block;
        content: "\\2022";
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        font-weight: 700;
        transform: scale(1.5);
        position: absolute;
        left: 2px;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 47%;
        padding-right: 30px;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        width: 32%;
    }
`;

export const MoreButton = styled.div`
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    justify-content: center;
    bottom: 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
    }
`;

export const LessButton = styled.div`
    display: none;
    padding-top: 15px;
    width: 100%;
    justify-content: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
    }
`;

export const ButtonText = styled.button`
    width: 200px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 16px;
    line-height: 22px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ShowMoreChevron = styled(ChevronDown)`
    width: 15px;
    height: 15px;
    margin-right: 10px;
`;

export const ShowLessChevron = styled(ChevronDown)`
    transform: rotate(180deg);
    width: 15px;
    height: 15px;
    margin-right: 10px;
`;
