import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";

import InfoIcon from "../../infoIcon/InfoIcon";
import { Column, Error, InfoIconWrapper, Root, Row, SelectContainerWrapper } from "../formStyles.sc";
import { RadioInput } from "./RadioInput";

interface RadioProps {
    parameter: GQLFinanceInformationParameter;
}

const Radio: React.FC<RadioProps> = ({ parameter }) => {
    const options = [];
    const labelExtendedOptions = [];

    for (const opt of parameter.options ?? []) {
        const splittedOption = opt.label?.split("<br/>") ?? [];

        if (splittedOption[1]) {
            labelExtendedOptions.push(splittedOption[1]);
        }
        options.push({ label: splittedOption[0], value: opt.value });
    }

    return (
        <Root key={parameter.id}>
            <Row>
                {parameter.label && <Column>{parameter.label} </Column>}
                <Column isFullWidth>
                    <SelectContainerWrapper flexStyle="row" selectStyle="radio">
                        {parameter.id &&
                            options &&
                            options.map((option) => (
                                <Field
                                    key={option.label}
                                    name={parameter.id ?? ""}
                                    component={RadioInput}
                                    type="radio"
                                    value={option.value}
                                    defaultValue={parameter.defaultOption}
                                    id={parameter.id ?? "" + option.value}
                                    hasExtendedValue={labelExtendedOptions.length > 0}
                                >
                                    {option.label}
                                </Field>
                            ))}
                    </SelectContainerWrapper>
                    {parameter.help && (
                        <InfoIconWrapper>
                            <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help ?? "" }} />} isClickable />
                        </InfoIconWrapper>
                    )}
                </Column>
            </Row>
            <Row>
                <Column isFullWidth>
                    <SelectContainerWrapper flexStyle="row" selectStyle="radio">
                        {labelExtendedOptions.length > 0 &&
                            labelExtendedOptions.map((extendedLabel) => <ExtendedLabel key={extendedLabel}>{extendedLabel}</ExtendedLabel>)}
                    </SelectContainerWrapper>
                </Column>
            </Row>
            <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />
        </Root>
    );
};

const ExtendedLabel = styled.div`
    display: flex;
    align-items: baseline;
    flex-basis: 50%;
    padding-top: 10px;
`;

export default Radio;
