import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { equipmentSort } from "@src/shared/utils/equipmentSort";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import * as sc from "./EquipmentContainer.sc";

interface IEquipmentContainerProps {
    equipment: NonNullable<GQLVehicleDetailQuery["vehicle"]["equipment"]>;
}

const Equipment: React.FunctionComponent<IEquipmentContainerProps> = ({ equipment }) => {
    const [isExpanded, setIsExpanded] = React.useState(equipment.length <= 18);
    const canExpanded = equipment.length > 18;
    const contentRef = React.useRef<HTMLDivElement | null>(null);

    const handleExpandableButton = () => {
        if (!isExpanded) {
            contentRef.current?.scrollIntoView();
        }
        setIsExpanded(!isExpanded);
    };

    return (
        <sc.EquipmentContainer ref={contentRef}>
            <sc.EquipmentHeadline>
                <sc.HorizontalLine />
                <sc.HeadlineText>
                    <FormattedMessage id="leaseme.equipment" defaultMessage="Ausstattung" />
                </sc.HeadlineText>
            </sc.EquipmentHeadline>

            <sc.OpacityBox showMore={isExpanded}>
                <sc.CarDataList>
                    {[...equipment].sort(equipmentSort).map((equipment, index) => {
                        return (
                            <sc.CarDataListItem key={index} title={equipment.text ?? ""}>
                                {equipment.text}
                            </sc.CarDataListItem>
                        );
                    })}
                </sc.CarDataList>
                {!isExpanded && (
                    <>
                        <sc.MoreButton>
                            <sc.ButtonText onClick={handleExpandableButton} data-gtm-event="offer-details" data-gtm-event-details="Equipment">
                                <sc.ShowMoreChevron />
                                <FormattedMessage id="leaseme.equipment.showMore" defaultMessage="Mehr anzeigen" />
                            </sc.ButtonText>
                        </sc.MoreButton>
                    </>
                )}
                {canExpanded && isExpanded && (
                    <sc.LessButton>
                        <sc.ButtonText onClick={handleExpandableButton} data-gtm-event="offer-details" data-gtm-event-details="Equipment">
                            <sc.ShowLessChevron />
                            <FormattedMessage id="leaseme.equipment.showLess" defaultMessage="Weniger anzeigen" />
                        </sc.ButtonText>
                    </sc.LessButton>
                )}
            </sc.OpacityBox>
        </sc.EquipmentContainer>
    );
};

export default Equipment;
