import { StyleSheet } from "@react-pdf/renderer";

export const financialDetailData = StyleSheet.create({
    infoContainer: {
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "0 15pt",
        marginBottom: "4pt",
        width: "100%",
    },
});
