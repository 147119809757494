import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const footerStyles = StyleSheet.create({
    pageNumber: {
        position: "absolute",
        bottom: "0.5cm",
        left: "0pt",
        right: "0pt",
        fontSize: "8pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
        textAlign: "center",
    },
});
