import { GQLBoniCheckParameterFragment } from "@src/graphql.apollo.generated";
import Button, { ButtonSize, ButtonVariant, ButtonWidth } from "@src/shared/button/Button";
import { FormApi, FormState } from "final-form";
import * as React from "react";
import { Form, FormSpy } from "react-final-form";
import { FormattedMessage } from "react-intl";

import * as sc from "./BoniCheckForm.sc";
import Section from "./Section";

export interface IBoniCheckProps {
    parameters?: GQLBoniCheckParameterFragment | null;
    isLoading?: boolean;
    onChange?: (formState: FormState<any>) => void;
    onSubmit: (values: FormData, form: FormApi, callback?: (errors?: object) => void) => object | Promise<object | undefined> | undefined | void;
    showResult?: boolean;
    reset: () => void;
}

const BoniCheckForm: React.FunctionComponent<IBoniCheckProps> = ({ parameters, isLoading, onChange, onSubmit, showResult, reset }) => {
    let boniCheckForm: any;

    const boniCheckRef = React.useRef<HTMLDivElement | null>(null);

    const handleSubmitClick = () => {
        const event = document.createEvent("Event");
        event.initEvent("submit", false, true);
        boniCheckForm.dispatchEvent(event);
        boniCheckRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    return (
        <sc.Wrapper ref={boniCheckRef}>
            {isLoading && <sc.Loading />}
            {parameters?.groups &&
                (showResult ? (
                    <sc.ResultWrapper key="boniCheckResult">
                        {parameters.groups
                            .filter((group: { name: string }) => group.name === "Ergebnis")
                            .map((group: any, index: number) => (
                                <React.Fragment key={`${index}-result`}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: group.parameters[0].label || "",
                                        }}
                                    />
                                    <sc.RepeatButton>
                                        <Button variant={ButtonVariant.Primary} onClick={reset} size={ButtonSize.Big}>
                                            QuickCheck neu starten
                                        </Button>
                                    </sc.RepeatButton>
                                    <sc.Disclaimer>{parameters.disclaimer}</sc.Disclaimer>
                                </React.Fragment>
                            ))}
                    </sc.ResultWrapper>
                ) : (
                    <>
                        <sc.Headline key="headline">{parameters.title}</sc.Headline>
                        <sc.InfoText key="infoText">{parameters.info}</sc.InfoText>
                        <Form
                            key="boniCheckForm"
                            onSubmit={onSubmit}
                            render={({ handleSubmit }) => (
                                <>
                                    <FormSpy
                                        key="formSpy"
                                        onChange={onChange}
                                        subscription={{
                                            values: true,
                                            modified: true,
                                            valid: true,
                                        }}
                                    />
                                    <sc.FormContainer>
                                        <sc.StyledForm onSubmit={handleSubmit} key="form" ref={(formObject: any) => (boniCheckForm = formObject)}>
                                            {parameters?.groups
                                                ?.filter((group: { name: string }) => group.name === "Persönliche Angaben")
                                                .map((group: any) => (
                                                    <Section key={group.name} data={group} errors={parameters.errors} />
                                                ))}
                                        </sc.StyledForm>
                                        <sc.SubmitWrapper key="submitWrapper">
                                            <sc.ButtonWrapper>
                                                <Button
                                                    variant={ButtonVariant.Primary}
                                                    onClick={handleSubmitClick}
                                                    type="submit"
                                                    disabled={!parameters.control.checkPossible}
                                                    size={ButtonSize.Big}
                                                    width={ButtonWidth.FullWidth}
                                                >
                                                    <sc.BoniButtonText>
                                                        <FormattedMessage id="leaseme.bonicheck.submit" defaultMessage="Bonität prüfen" />
                                                    </sc.BoniButtonText>
                                                </Button>
                                            </sc.ButtonWrapper>
                                        </sc.SubmitWrapper>
                                    </sc.FormContainer>
                                </>
                            )}
                        />
                    </>
                ))}
        </sc.Wrapper>
    );
};

export default BoniCheckForm;
