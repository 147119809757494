import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const equipmentStyles = StyleSheet.create({
    root: {
        flexDirection: "column",
    },
    horizontalLine: {
        width: "80pt",
        height: "2pt",
        backgroundColor: theme.colors.darkRed,
    },
    title: {
        fontSize: "9pt",
        fontFamily: "MarkW1G",
        fontWeight: "bold",
        color: theme.colors.porscheBankBlue,
        paddingBottom: "15pt",
        paddingTop: "8pt",
        textTransform: "uppercase",
    },
    content: {
        flexDirection: "row",
    },
    contentLeft: {
        width: "50%",
    },
    contentRight: {
        width: "50%",
    },
    equipment: {
        fontSize: "8pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
        paddingBottom: "8pt",
    },
    point: {
        color: theme.colors.porscheBankBlue,
        paddingRight: "4pt",
        fontSize: "8pt",
    },
    equipmentContainer: {
        flexDirection: "row",
    },
});
