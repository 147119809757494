import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const financialDetailDataEntryStyles = StyleSheet.create({
    dataContainer: {
        marginBottom: "8pt",
        marginRight: "8pt",
        width: "30mm",
    },
    detailValue: {
        fontSize: "9pt",
        fontWeight: "bold",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
    },
    detailDescription: {
        marginTop: "4pt",
        fontSize: "8pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
    },
});
