import * as React from "react";
import { Field } from "react-final-form";

import * as sc from "./Input.sc";

export interface IInputProps {
    type: string;
    value: string;
    name: string;
    label: string;
    maxLength?: number;
    errorMessage?: string;
    startAdornment?: string;
    help?: JSX.Element;
}

const Input: React.FunctionComponent<IInputProps> = ({ type, value, name, maxLength, errorMessage, startAdornment, help }) => {
    return (
        <>
            <Field
                name={name}
                type={type}
                initialValue={value}
                maxLength={maxLength}
                parse={(inputValue) => (inputValue === "" && type === "number" ? undefined : inputValue)}
                render={({ input }) => {
                    return (
                        <sc.Root startAdornment={startAdornment}>
                            <sc.Input
                                {...input}
                                type={type}
                                maxLength={maxLength}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => input.onChange(event.target?.value)}
                                hasStartAdornment={!!startAdornment}
                                hasEndAdornment={!!help}
                            />
                            {help}
                        </sc.Root>
                    );
                }}
            />
            {errorMessage && <sc.Error>{errorMessage}</sc.Error>}
        </>
    );
};

export default Input;
