import styled from "styled-components";

export const DesktopContainer = styled.div`
    display: none;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: inline-block;
        width: 100%;
        margin-bottom: 60px;
        margin-top: 40px;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const DropdownHeadline = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0;
    padding-top: 10px;
    font-weight: 700;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 40px;
        padding: 0;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0;
    }
`;

export const CarDataEquipmentWrapper = styled.div`
    padding: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 20px 0;
        box-sizing: border-box;
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0;
    }
`;

export const HeadlineText = styled.h1`
    margin-bottom: 0;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const MobileBorderSmall = styled.div`
    width: calc(100vw - 40px);
    margin-left: -15px;
    height: 1px;
    background: ${({ theme }) => theme.colors.borderGray};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const DetailInfoWrapper = styled.div`
    padding: 20px 35px;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 20px;
        box-sizing: border-box;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        padding: 0;
    }
`;
