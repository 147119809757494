import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";

import * as sc from "./ShareMenu.sc";

export interface IShareMenuProps {
    shareMenuOpen: boolean;
    handleShareMenuClose: () => void;
    shareMenuItems: React.ReactElement;
}

const ShareMenu: React.FunctionComponent<IShareMenuProps> = ({ shareMenuOpen, handleShareMenuClose, shareMenuItems }) => {
    return (
        <sc.ToolTip>
            <sc.StyledShareIcon />
            <sc.InnerToolTip shareMenuOpen={shareMenuOpen}>
                <OutsideClickHandler onOutsideClick={handleShareMenuClose}>
                    <sc.ToolTipText shareMenuOpen={shareMenuOpen}>{shareMenuItems}</sc.ToolTipText>
                </OutsideClickHandler>
            </sc.InnerToolTip>
        </sc.ToolTip>
    );
};

export default ShareMenu;
