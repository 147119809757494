import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import Equipment from "@src/shared/vehicleDetail/equipment/EquipmentContainer";
import React from "react";
import { FormattedMessage } from "react-intl";

import DealerInfo from "../dealer/DealerInfo";
import Dropdown from "./Dropdown";
import * as sc from "./MobileDropdown.sc";

interface MobileDropdownProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    carDataContent: React.ReactElement;
    finCalcLightbox?: React.ReactElement;
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({ vehicle, children, carDataContent, finCalcLightbox }) => {
    const [isOpen, setIsOpen] = React.useState({
        vehicleDetail: false,
        dealer: false,
        equipmentDetail: false,
    });

    const onVehicleDetailDropdownClicked = () => {
        setIsOpen({ ...isOpen, vehicleDetail: !isOpen.vehicleDetail });
    };

    const onEquipmentDetailDropdownClicked = () => {
        setIsOpen({ ...isOpen, equipmentDetail: !isOpen.equipmentDetail });
    };

    const onDealerDropdownClicked = () => {
        setIsOpen({ ...isOpen, dealer: !isOpen.dealer });
    };

    return (
        <sc.MobileDropdownContainer>
            <Dropdown
                header={
                    <div>
                        <sc.HorizontalLine />
                        <sc.DropdownHeadline>
                            <FormattedMessage id="leaseme.vehicleData" defaultMessage="Fahrzeugdaten" />
                        </sc.DropdownHeadline>
                    </div>
                }
                open={isOpen.vehicleDetail}
                onHeaderClick={onVehicleDetailDropdownClicked}
            >
                <sc.CarDataEquipmentWrapper>{carDataContent}</sc.CarDataEquipmentWrapper>
            </Dropdown>
            {((vehicle.equipment && vehicle.equipment.length > 0) || (vehicle.nwSeriesEquipment && vehicle.nwSeriesEquipment.length > 0)) && (
                <Dropdown
                    header={
                        <div>
                            <sc.HorizontalLine />
                            <sc.DropdownHeadline>
                                <FormattedMessage id="leaseme.equipment" defaultMessage="Ausstattung" />
                            </sc.DropdownHeadline>
                        </div>
                    }
                    open={isOpen.equipmentDetail}
                    onHeaderClick={onEquipmentDetailDropdownClicked}
                >
                    <sc.CarDataEquipmentWrapper>
                        {vehicle.nwSeriesEquipment && vehicle.nwSeriesEquipment.length > 0 ? (
                            <Equipment equipment={vehicle.nwSeriesEquipment} />
                        ) : (
                            vehicle.equipment && vehicle.equipment.length > 0 && <Equipment equipment={vehicle.equipment} />
                        )}
                    </sc.CarDataEquipmentWrapper>
                </Dropdown>
            )}

            {finCalcLightbox}

            <Dropdown
                header={
                    <div>
                        <sc.HorizontalLine />
                        <sc.DropdownHeadline>
                            <FormattedMessage id="leaseme.dealerInformation" defaultMessage="Händlerinformation" />
                        </sc.DropdownHeadline>
                    </div>
                }
                open={isOpen.dealer}
                onHeaderClick={onDealerDropdownClicked}
            >
                {vehicle.dealer && <DealerInfo dealer={vehicle.dealer} />}
            </Dropdown>
            {children}
        </sc.MobileDropdownContainer>
    );
};

export default MobileDropdown;
