import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";

type Equipment = NonNullable<GQLVehicleDetailQuery["vehicle"]["equipment"]>[number];

export const equipmentSort = (a: Equipment, b: Equipment) => {
    if (!b.text) {
        return -1;
    }
    if (!a.text) {
        return 1;
    }
    return a.text?.localeCompare(b.text);
};
