import * as FacebookIcon from "@src/shared/assets/images/facebook.inline.svg";
import * as LinkIcon from "@src/shared/assets/images/link.inline.svg";
import * as MailIcon from "@src/shared/assets/images/mail.inline.svg";
import * as ShareIcon from "@src/shared/assets/images/share.inline.svg";
import * as TwitterIcon from "@src/shared/assets/images/twitter.inline.svg";
import * as ViberIcon from "@src/shared/assets/images/viber.inline.svg";
import * as WhatsAppIcon from "@src/shared/assets/images/whatsapp.inline.svg";
import styled from "styled-components";

export const ToolTip = styled.div`
    flex-basis: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
`;

interface IProps {
    shareMenuOpen: boolean;
}

export const StyledShareIcon = styled(ShareIcon)`
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
    width: 20px;
    height: 20px;
    display: none;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
    }
`;

export const InnerToolTip = styled.div<IProps>`
    display: ${({ shareMenuOpen }) => (shareMenuOpen ? "block" : "none")};
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 6;
    padding-top: 50px;
    left: 0;
    background-color: transparent;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        padding-top: 0;
        top: 20px;
    }
`;

export const ToolTipText = styled.div<IProps>`
    display: ${({ shareMenuOpen }) => (shareMenuOpen ? "flex" : "none")};
    box-sizing: border-box;
    font-size: 16px;
    background: white;
    position: fixed;
    z-index: 10;
    right: 0;
    left: 0;
    text-align: center;
    flex-direction: column;
    line-height: 30px;
    bottom: 0;
    box-shadow: 0 0 10px 200vh rgba(0, 0, 0, 0.5);

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        border-radius: 6px;
        text-align: left;
        width: 200px;
        margin-right: -12px;
        margin-top: 25px;
        left: unset;
        margin-left: unset;
        height: unset;
        bottom: unset;
        position: absolute;
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    }
`;

export const ShareMenuItem = styled.a`
    display: flex;
    padding: 8px 25px;
    align-items: center;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightGray};
    }
    &:not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
    }
`;

export const StyledMailIcon = styled(MailIcon)`
    fill: ${({ theme }) => theme.colors.iconGray};
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

export const StyledLinkIcon = styled(LinkIcon)`
    fill: ${({ theme }) => theme.colors.iconGray};
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

export const StyledFacebookIcon = styled(FacebookIcon)`
    fill: ${({ theme }) => theme.colors.facebook};
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

export const StyledTwitterIcon = styled(TwitterIcon)`
    fill: ${({ theme }) => theme.colors.twitter};
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

export const StyledWhatsAppIcon = styled(WhatsAppIcon)`
    fill: ${({ theme }) => theme.colors.whatsApp};
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

export const StyledViberIcon = styled(ViberIcon)`
    fill: ${({ theme }) => theme.colors.viber};
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;
