import { getConfig } from "@src/config";
import { useFinanceRuntimeOptions } from "@src/shared/context/searchfilter/Options";
import SingleChoiceFilter from "@src/shared/searchResults/searchfilter/filter/singlechoicefilter/SingleChoiceFilter";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import React from "react";
import { FormattedMessage } from "react-intl";

import * as sc from "./FinCalcLightbox.sc";

const FinCalcLightbox: React.FC = () => {
    const financeRuntimeOptions = useFinanceRuntimeOptions(getConfig("scope").domain);

    const downpaymentOptions = [
        {
            value: 25,
            label: `25%`,
        },
        {
            value: 30,
            label: `30%`,
        },
        {
            value: 40,
            label: `40%`,
        },
        {
            value: 50,
            label: `50%`,
        },
    ];

    const [filterData, setFilterData] = React.useState<{
        duration: number;
        downpayment: number;
    }>({ duration: 48, downpayment: 25 });

    return (
        <sc.Root>
            <sc.DropdownHeadline>
                <sc.HorizontalLine />
                <sc.HeadlineText>
                    <FormattedMessage id="leaseme.finCalcLeasing" defaultMessage="Leasingfinanzierung klassisch" />
                </sc.HeadlineText>
            </sc.DropdownHeadline>
            <sc.Section>
                <sc.SubHeadline>
                    <FormattedMessage id="leaseme.finCalcLeasing.financing" defaultMessage="Finanzierung" />
                </sc.SubHeadline>
                <sc.Row>
                    <sc.Column>
                        <FormattedMessage id="leaseme.finCalcLeasing.vehiclePriceVAT" defaultMessage="Fahrzeugpreis inkl. MwSt." />
                    </sc.Column>
                    <sc.Column>
                        <FormattedCurrency value={30000} />
                    </sc.Column>
                </sc.Row>
                <sc.Row>
                    <sc.Column>
                        <FormattedMessage id="leaseme.runtime.label" defaultMessage="Laufzeit" />
                    </sc.Column>
                    <sc.Column>
                        <sc.SelectContainerWrapper>
                            <SingleChoiceFilter<number>
                                value={filterData.duration}
                                options={financeRuntimeOptions}
                                onChange={(value) => {
                                    setFilterData({
                                        ...filterData,
                                        duration: value || 0,
                                    });
                                }}
                            />
                        </sc.SelectContainerWrapper>
                    </sc.Column>
                </sc.Row>
                <sc.Row>
                    <sc.Column>
                        <FormattedMessage id="leaseme.finCalcLeasing.downpaymentPercent" defaultMessage="Eigenleistung in %" />
                    </sc.Column>
                    <sc.Column>
                        <sc.SelectContainerWrapper>
                            <SingleChoiceFilter<number>
                                value={filterData.downpayment}
                                options={downpaymentOptions}
                                onChange={(value) => {
                                    setFilterData({
                                        ...filterData,
                                        downpayment: value || 0,
                                    });
                                }}
                            />
                        </sc.SelectContainerWrapper>
                    </sc.Column>
                </sc.Row>
                <sc.Row>
                    <sc.Column>
                        <FormattedMessage id="leaseme.finCalcLeasing.downpaymentAbsolute" defaultMessage="Eigenleistung in absoluten Werten" />
                    </sc.Column>
                    <sc.Column>
                        <FormattedCurrency value={5000} />
                    </sc.Column>
                </sc.Row>
                <sc.Row>
                    <sc.Column>
                        <FormattedMessage id="leaseme.finCalcLeasing.residualValuePercent" defaultMessage="Restwert in %" />
                    </sc.Column>
                    <sc.Column>20%</sc.Column>
                </sc.Row>
                <sc.Row>
                    <sc.ColumnBold>
                        <FormattedMessage
                            id="leaseme.finCalcLeasing.rateVAT"
                            defaultMessage="Monatliche Leasingrate inkl. MwSt.
                            "
                        />
                    </sc.ColumnBold>
                    <sc.ColumnBold>
                        <FormattedCurrency value={299} />
                    </sc.ColumnBold>
                </sc.Row>
            </sc.Section>
            <sc.Section>
                <sc.SubHeadline>
                    <FormattedMessage id="leaseme.finCalcLeasing.insurance" defaultMessage="Versicherung" />
                </sc.SubHeadline>
                <sc.Row>
                    <sc.ColumnBold>
                        <FormattedMessage
                            id="leaseme.finCalcLeasing.cascoRate"
                            defaultMessage="Monatliche Kasko-Rate
                            "
                        />
                    </sc.ColumnBold>
                    <sc.ColumnBold>
                        <FormattedCurrency value={145} />
                    </sc.ColumnBold>
                </sc.Row>
            </sc.Section>
            <sc.Section>
                <sc.SubHeadline>
                    <FormattedMessage id="leaseme.finCalcLeasing.total" defaultMessage="Gesamt" />
                </sc.SubHeadline>
                <sc.Row>
                    <sc.Column>
                        <FormattedMessage
                            id="leaseme.finCalcLeasing.leasingRate"
                            defaultMessage="Monatliche Leasing-Rate
                            "
                        />
                    </sc.Column>
                    <sc.Column>
                        <FormattedCurrency value={299} />
                    </sc.Column>
                </sc.Row>
                <sc.Row>
                    <sc.Column>
                        <FormattedMessage
                            id="leaseme.finCalcLeasing.cascoRate"
                            defaultMessage="Monatliche Kasko-Rate
                            "
                        />
                    </sc.Column>
                    <sc.Column>
                        <FormattedCurrency value={145} />
                    </sc.Column>
                </sc.Row>
                <sc.Row>
                    <sc.ColumnBold>
                        <FormattedMessage
                            id="leaseme.finCalcLeasing.rate"
                            defaultMessage="Monatliche Gesamtrate
                            "
                        />
                    </sc.ColumnBold>
                    <sc.ColumnBold>
                        <FormattedCurrency value={365} />
                    </sc.ColumnBold>
                </sc.Row>
            </sc.Section>
        </sc.Root>
    );
};

export default FinCalcLightbox;
