import { useApolloClient } from "@apollo/client";
import { getConfig } from "@src/config";
import {
    GQLFinanceInformationQuery,
    GQLFinanceInformationQueryVariables,
    GQLFinanceType,
    GQLVehicleDetailQuery,
} from "@src/graphql.apollo.generated";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import ContactForm from "@src/shared/vehicleDetail/contactForm/ContactForm";
import { DealerButton } from "@src/skeleton/vehicleDetail/imageContentSection/DealerButton";
import { OnlineClosureButton } from "@src/skeleton/vehicleDetail/imageContentSection/OnlineClosureButton";
import { FORM_ERROR } from "final-form";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useFinCalc from "../context/fincalc/useFinCalcData";
import { getDefaultFilter, IFilter } from "../context/searchfilter/IFilter";
import useSearchFilter from "../context/searchfilter/useSearchFilter";
import FinanceTypeSwitch from "../vehicleDetail/financeTypeSwitch/FinanceTypeSwitch";
import { financeInformationQuery } from "./FinCalcLightbox.gql";
import * as sc from "./FinCalcLightbox.sc";
import FinCalcLightboxForm from "./FinCalcLightboxForm";

const getDownPayment = (values: any, filter: IFilter) => {
    if (Number(values.DownPayment) >= 0) {
        return Number(values.DownPayment);
    } else if (Number(values.DownpaymentDealer) >= 0) {
        return Number(values.DownpaymentDealer);
    } else if (Number(values.DownpaymentAmount) >= 0) {
        return Number(values.DownpaymentAmount);
    } else {
        return filter.downPayment;
    }
};

interface FinCalcLightboxProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const FinCalcLightbox: React.FC<FinCalcLightboxProps> = ({ vehicle }) => {
    const { filter, setFilters, setFilter } = useSearchFilter();
    const intl = useIntl();
    const [contactFormOpen, setContactFormOpen] = React.useState(false);
    const [onlineClosureFormOpen, setOnlineClosureFormOpen] = React.useState(false);

    const contactRef = React.useRef<Element>();
    const onlineClosureRef = React.useRef<Element>();

    const { financeInformation, setFinCalcInformation } = useFinCalc();

    const client = useApolloClient();

    const handleContactFormClose = () => {
        setContactFormOpen(false);
    };

    const handleOnlineClosureFormClose = () => {
        setOnlineClosureFormOpen(false);
    };

    const handleContactButtonClick = () => {
        setContactFormOpen(true);
        setOnlineClosureFormOpen(false);

        pushToDataLayer({
            event: "dealer-contact-form-open",
        });
    };

    const handleOnlineClosureButtonClick = () => {
        setOnlineClosureFormOpen(true);
        setContactFormOpen(false);

        pushToDataLayer({
            event: "online-closure-form-open",
        });
    };

    const getFinanceInformations = React.useCallback(
        async (
            id: string,
            financeType: GQLFinanceType | undefined,
            downPayment: number,
            financeRuntime: number,
            yearlyMileage: number,
            residualCreditInPercent: number | undefined,
        ) => {
            return await client.query<GQLFinanceInformationQuery, GQLFinanceInformationQueryVariables>({
                query: financeInformationQuery,
                variables: {
                    id,
                    leasemeScope: getConfig("scope"),
                    financeType,
                    args: {
                        downPayment,
                        financeRuntime,
                        yearlyMileage,
                        residualCreditInPercent,
                    },
                },
                fetchPolicy: "no-cache",
            });
        },
        [client],
    );

    React.useEffect(() => {
        const vehicleId = vehicle.vehicleId;

        if (!vehicleId) {
            return;
        }

        const fetchData = async () => {
            const { data } = await getFinanceInformations(
                vehicleId,
                filter.financeType,
                filter.downPayment,
                filter.financeRuntime,
                filter.kmPerYear,
                filter.remainingCredit,
            );

            setFinCalcInformation(data.vehicle.financeInformation ?? undefined);
        };

        fetchData();
    }, [filter, vehicle.vehicleId, setFinCalcInformation, getFinanceInformations]);

    const handleSubmit = async (values: any) => {
        const vehicleId = vehicle.vehicleId;

        if (!vehicleId) {
            return;
        }

        const data = await getFinanceInformations(
            vehicleId,
            filter.financeType,
            getDownPayment(values, filter),
            Number(values.Duration) || getDefaultFilter(getConfig("scope").domain).financeRuntime,
            Number(values.AnnualMileage) || getDefaultFilter(getConfig("scope").domain).kmPerYear,
            Number(values.ResidualCreditInPercent || values.ResidualValuePercentage || getDefaultFilter(getConfig("scope").domain).remainingCredit),
        );

        if (data.error) {
            return {
                [FORM_ERROR]: data.error,
            };
        }

        const financeInformation = data.data.vehicle.financeInformation;

        if (financeInformation?.errors && financeInformation.errors.length > 0) {
            const formErrors: { description: string }[] = [];
            financeInformation.errors.map((error: { type: string; group: any; field: any; description: string; id: string }) =>
                formErrors.push({ description: error.description }),
            );

            return {
                [FORM_ERROR]: formErrors,
            };
        }

        setFinCalcInformation(financeInformation ?? undefined);

        const hasParameterError = financeInformation?.groups.some((group) => group.parameters.some((parameter) => parameter.error != null)) ?? false;

        if (hasParameterError) {
            return {
                [FORM_ERROR]: {
                    description: intl.formatMessage({
                        id: "leaseme.lightbox.error",
                        defaultMessage: "Eine oder mehrere Angaben ist ungültig. Bitte überprüfen Sie Ihre Eingaben",
                    }),
                },
            };
        }

        if (setFilters) {
            setFilters(
                [
                    { key: "financeRuntime", value: Number(values.Duration) || getDefaultFilter(getConfig("scope").domain).financeRuntime },
                    {
                        key: "remainingCredit",
                        value: Number(
                            values.ResidualCreditInPercent ||
                                values.ResidualValuePercentage ||
                                getDefaultFilter(getConfig("scope").domain).remainingCredit,
                        ),
                    },
                    {
                        key: "downPayment",
                        value: getDownPayment(values, filter),
                    },
                    { key: "kmPerYear", value: Number(values.AnnualMileage) || getDefaultFilter(getConfig("scope").domain).kmPerYear },
                ],
                false,
            );
        }
    };

    if (!financeInformation) {
        return <sc.Loading />;
    }

    return (
        <sc.Root>
            <sc.DropdownHeadline>
                <sc.HorizontalLine />
                <sc.HeadlineText>
                    <FormattedMessage id="leaseme.finCalc.financing" defaultMessage="Finanzierung über die Porsche Bank" />
                </sc.HeadlineText>
            </sc.DropdownHeadline>
            <FinanceTypeSwitch
                financeType={filter.financeType}
                setFinanceType={(newFinanceType) => setFilter && setFilter("financeType", newFinanceType, false)}
                leasingCapable={vehicle.leasingRate !== null}
            />
            {financeInformation.globalDisclaimer && (
                <sc.Disclaimer
                    dangerouslySetInnerHTML={{
                        __html: financeInformation.globalDisclaimer ?? "",
                    }}
                />
            )}

            <FinCalcLightboxForm data={financeInformation} onSubmit={handleSubmit} />

            {getConfig("scope").domain === "lease-me.porschebank.at" && (
                <>
                    <sc.DealerClosureButtonsWrapper>
                        <DealerButton vehicle={vehicle} onClick={handleContactButtonClick} />
                        <OnlineClosureButton onClick={handleOnlineClosureButtonClick} />
                    </sc.DealerClosureButtonsWrapper>
                    {contactFormOpen && (
                        <ContactForm handleClose={handleContactFormClose} vehicle={vehicle} visibleTab={filter.financeType} refProp={contactRef} />
                    )}
                    {onlineClosureFormOpen && (
                        <ContactForm
                            handleClose={handleOnlineClosureFormClose}
                            vehicle={vehicle}
                            visibleTab={filter.financeType}
                            refProp={onlineClosureRef}
                            type="ONLINE_CLOSURE"
                        />
                    )}
                </>
            )}

            {getConfig("scope").domain !== "car4me.si" && financeInformation.productDisclaimer && (
                <sc.Disclaimer
                    dangerouslySetInnerHTML={{
                        __html: `** ${financeInformation.productDisclaimer}` ?? "",
                    }}
                />
            )}
        </sc.Root>
    );
};

export default React.memo(FinCalcLightbox);
