import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import * as React from "react";
import { Field } from "react-final-form";

import InfoIcon from "../../infoIcon/InfoIcon";
import { Column, Error, InfoIconWrapper, Root, Row, SelectContainerWrapper, Unit } from "../formStyles.sc";
import Input from "./Input";

export interface EditProps {
    parameter: GQLFinanceInformationParameter;
}

const Edit: React.FunctionComponent<EditProps> = ({ parameter }) => {
    return (
        <Root key={parameter.id}>
            <Row>
                <Column>{parameter.label} </Column>
                <Column>
                    {parameter.unit && <Unit hasError={parameter.error != null}>{parameter.unit}</Unit>}
                    <SelectContainerWrapper>
                        {parameter.defaultOption && parameter.id && (
                            <Field
                                name={parameter.id}
                                initialValue={parameter.defaultOption}
                                maxLength={Number(parameter.length) ?? 10}
                                component={Input}
                                hasError={parameter.error != null}
                                type={parameter.dataType === "Decimal" ? "number" : "text"}
                            />
                        )}
                    </SelectContainerWrapper>
                    {parameter.help && (
                        <InfoIconWrapper>
                            <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help ?? "" }} />} isClickable />
                        </InfoIconWrapper>
                    )}
                </Column>
            </Row>
            <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />
        </Root>
    );
};

export default Edit;
