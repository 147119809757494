import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const headerStyles = StyleSheet.create({
    root: {
        marginBottom: "15pt",
    },
    logoImage: {
        position: "absolute",
        top: "15pt",
        right: "30pt",
        height: "30pt",
        backgroundColor: theme.colors.porscheBankBlue,
        maxWidth: "120pt",
    },
});
