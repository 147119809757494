import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import * as React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import * as sc from "./PriceInfoValues.sc";

interface IPriceInfoValuesProps {
    data: GQLVehicleDetailQuery["vehicle"];
}

const PriceInfoValues: React.FunctionComponent<IPriceInfoValuesProps> = ({ data }) => {
    const { filter } = useSearchFilter();
    const intl = useIntl();

    const rate = data.finCalcData?.rate;
    const downPayment = data.finCalcData?.downPayment;

    return (
        <>
            <sc.ContentValues>
                <sc.ResidualValue>
                    <sc.ResidualValueTitle>
                        <FormattedMessage id="leaseme.priceInfo.residualValue.leasing" defaultMessage="Restwert-Leasing" />
                    </sc.ResidualValueTitle>
                    <sc.AmountTextContainer>
                        <sc.Amount>
                            <FormattedCurrency value={rate} maximumFractionDigits={2} minimumFractionDigits={2} />
                            {" *"}
                        </sc.Amount>
                        <sc.ResidualValueText>
                            <FormattedMessage id="leaseme.perMonth" defaultMessage="pro Monat" />
                        </sc.ResidualValueText>
                    </sc.AmountTextContainer>
                </sc.ResidualValue>
                <sc.Values>
                    <sc.Value>
                        <sc.ValueHeader>
                            <FormattedMessage
                                id="leaseme.runtime.value"
                                defaultMessage="{financeRuntime} Monate"
                                values={{
                                    financeRuntime: intl.formatNumber(filter.financeRuntime),
                                }}
                            />
                        </sc.ValueHeader>
                        <sc.ValueContent>
                            {" "}
                            <FormattedMessage id="leaseme.runtime.label" defaultMessage="Laufzeit" />
                        </sc.ValueContent>
                    </sc.Value>

                    <sc.Value>
                        <sc.ValueHeader>
                            <FormattedNumber value={filter.kmPerYear} /> <FormattedMessage id="leaseme.km" defaultMessage="km" />
                        </sc.ValueHeader>
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.perYear" defaultMessage="pro Jahr" />
                        </sc.ValueContent>
                    </sc.Value>

                    <sc.Value>
                        <sc.ValueHeader>
                            <FormattedCurrency value={downPayment} />
                        </sc.ValueHeader>
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.downPayment.label" defaultMessage="Eigenleistung" />
                        </sc.ValueContent>
                    </sc.Value>

                    <sc.Value>
                        <sc.ValueHeader>
                            <FormattedCurrency value={data.finCalcData?.residualValue} />{" "}
                            <InfoIcon
                                alignment="right"
                                infoText={intl.formatMessage({
                                    id: "leaseme.residualValue.info",
                                    defaultMessage:
                                        "Am Ende des Vertrages können Sie das Fahrzeug erwerben, indem Sie den Restwert abbezahlen, oder wir können Ihnen den Restwert stunden.",
                                })}
                            />
                        </sc.ValueHeader>
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.residualValue.label" defaultMessage="Restwert" />
                        </sc.ValueContent>
                    </sc.Value>
                </sc.Values>
            </sc.ContentValues>
        </>
    );
};

export default PriceInfoValues;
