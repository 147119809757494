import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./CarDataContent.sc";
import VehicleDataContainer from "./VehicleDataContainer";

interface ICarDataContentProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const CarDataContent: React.FunctionComponent<ICarDataContentProps> = ({ vehicle }) => {
    const intl = useIntl();

    const emissionLabel = (
        <>
            <FormattedMessage id="leaseme.emission" defaultMessage={"CO\u2082-Emission"} />
        </>
    );

    return (
        <sc.CarDataContent>
            <sc.CarDataContainer>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.color",
                            defaultMessage: "Farbe",
                        })}
                        value={vehicle.bodyColor?.text ?? "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.mileage",
                            defaultMessage: "Kilometerstand",
                        })}
                        value={
                            vehicle.mileage
                                ? `${intl.formatNumber(vehicle.mileage)} ${intl.formatMessage({
                                      id: "leaseme.km",
                                      defaultMessage: "km",
                                  })} ${getConfig("scope").domain === "lease-me.porschebank.at" ? "*" : ""}`
                                : `0 ${intl.formatMessage({
                                      id: "leaseme.km",
                                      defaultMessage: "km",
                                  })} ${getConfig("scope").domain === "lease-me.porschebank.at" ? "*" : ""}`
                        }
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.firstRegistration",
                            defaultMessage: "Erstzulassung",
                        })}
                        value={vehicle?.firstRegistration ? format(parseISO(vehicle.firstRegistration), "MM/yyyy") : "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.power",
                            defaultMessage: "Leistung",
                        })}
                        value={
                            vehicle.powerPS
                                ? intl.formatMessage(
                                      {
                                          id: "leaseme.powerValue",
                                          defaultMessage: "{powerPS} PS",
                                      },
                                      {
                                          powerPS: vehicle.powerPS,
                                      },
                                  )
                                : "-"
                        }
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.fuelConsumption",
                            defaultMessage: "Komb. Kraftstoffverbr.",
                        })}
                        value={vehicle.fuel?.consumption?.fmt ?? "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.fuelType",
                            defaultMessage: "Kraftstoffart",
                        })}
                        value={vehicle.fuel?.text ?? "-"}
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={emissionLabel}
                        value={vehicle.fuel?.co2?.fmt ?? "-"}
                        infoIconText={
                            getConfig("scope").domain === "car4me.bg" ? (
                                <FormattedMessage
                                    id="leaseme.co2.disclaimer"
                                    defaultMessage="EU-Angaben zum Kraftstoffverbrauch und zu den CO2-Emissionen gemäß der Verordnung. (EG) 715/2007. Die Angaben beziehen sich nicht auf ein einzelnes Fahrzeug und sind nicht Bestandteil des Angebots, sondern dienen allein Vergleichszwecken zwischen verschiedenen Fahrzeugtypen. Insbesondere Zusatzausstattungen und Zubehör (z. B. breitere Reifen, Klimaanlage, Dachgepäckträger usw.) können zu unterschiedlichen Kraftstoffverbrauchs- und CO2-Emissionswerten führen."
                                />
                            ) : undefined
                        }
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.offerRefference",
                            defaultMessage: "Bestandsnr.",
                        })}
                        value={vehicle.offerReference ?? "-"}
                    />
                </sc.CarDataRow>
            </sc.CarDataContainer>
        </sc.CarDataContent>
    );
};

export default CarDataContent;
