import { Image, Text, View } from "@react-pdf/renderer";
import SharedHeader from "@src/shared/vehicleDetail/pdf/header/Header";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import car4meROLogo from "../../../../../static/ro/car_4_me_white.png";
import Logo_ro from "../../../../../static/ro/pdfLogoPorschebank.png";
import { headerStyles } from "./HeaderStyles";

const Header: React.FC = () => {
    return (
        <SharedHeader logo={Logo_ro}>
            <View style={headerStyles.headerContent}>
                <View style={headerStyles.textContainer}>
                    <View style={headerStyles.headlineLogo}>
                        <Image src={car4meROLogo} />
                    </View>
                    <Text style={headerStyles.textSubHeadline}>
                        <FormattedMessage id="leaseme.pdf.subheadline" defaultMessage="Ein eigenes Auto um jeden Preis" />
                    </Text>
                </View>
            </View>
        </SharedHeader>
    );
};

export default Header;
