import BoniCheckMaster from "@src/AT/vehicleDetail/boniCheck/BoniCheckMaster";
import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import Anchor from "@src/shared/anchor/Anchor";
import FinCalcLightbox from "@src/shared/finCalcLightbox/FinCalcLightbox";
import CarDataContent from "@src/shared/vehicleDetail/carDataContent/CarDataContent";
import Dropdown from "@src/shared/vehicleDetail/mobile/Dropdown";
import SharedMobileDropdown from "@src/shared/vehicleDetail/mobile/MobileDropdown";
import * as sc from "@src/shared/vehicleDetail/mobile/MobileDropdown.sc";
import React from "react";
import { FormattedMessage } from "react-intl";

interface MobileDropdownProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const MobileFinCalcLightbox: React.FC<MobileDropdownProps> = ({ vehicle }) => {
    const [isfinCalcLightboxOpen, setIsfinCalcLightboxOpen] = React.useState(false);

    const onFinCalcLightboxDropdownClicked = () => {
        setIsfinCalcLightboxOpen(!isfinCalcLightboxOpen);
    };
    return (
        <Dropdown
            header={
                <div>
                    <Anchor id="lightboxMobile" />
                    <sc.HorizontalLine />
                    <sc.DropdownHeadline>
                        <FormattedMessage id="leaseme.financeInformation.bank" defaultMessage="Finanzierung über die Porsche Bank" />
                    </sc.DropdownHeadline>
                </div>
            }
            open={isfinCalcLightboxOpen}
            onHeaderClick={onFinCalcLightboxDropdownClicked}
        >
            <FinCalcLightbox vehicle={vehicle} />
        </Dropdown>
    );
};

const MobileDropdown: React.FC<MobileDropdownProps> = ({ vehicle }) => {
    const [isBoniCheckOpen, setIsBoniCheckOpen] = React.useState(false);

    const onBoniCheckDropdownClicked = () => {
        setIsBoniCheckOpen(!isBoniCheckOpen);
    };

    return (
        <SharedMobileDropdown
            vehicle={vehicle}
            carDataContent={<CarDataContent vehicle={vehicle} />}
            finCalcLightbox={getConfig("hasFinCalcLightbox") ? <MobileFinCalcLightbox vehicle={vehicle} /> : undefined}
        >
            <Dropdown
                header={
                    <div>
                        <sc.HorizontalLine />
                        <sc.DropdownHeadline>
                            <FormattedMessage id="leaseme.boniCheck" defaultMessage="Bonitäts-QuickCheck" />
                        </sc.DropdownHeadline>
                    </div>
                }
                open={isBoniCheckOpen}
                onHeaderClick={onBoniCheckDropdownClicked}
            >
                <BoniCheckMaster vehicleId={vehicle.vehicleId!} />
            </Dropdown>
        </SharedMobileDropdown>
    );
};

export default MobileDropdown;
