import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import Anchor from "@src/shared/anchor/Anchor";
import FinCalcLightbox from "@src/shared/finCalcLightbox/FinCalcLightbox";
import CarDataContent from "@src/shared/vehicleDetail/carDataContent/CarDataContent";
import SharedDesktopContainer from "@src/shared/vehicleDetail/desktop/DesktopContainer";
import DisclaimerSI from "@src/SI/vehicleDetail/disclaimer/Disclaimer";
import React from "react";

interface DesktopContainerProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const DesktopFinCalcLightbox: React.FC<DesktopContainerProps> = ({ vehicle }) => {
    return (
        <section>
            <Anchor id="lightboxDesktop" />
            <FinCalcLightbox vehicle={vehicle} />
            <DisclaimerSI />
        </section>
    );
};

const DesktopContainer: React.FC<DesktopContainerProps> = ({ vehicle }) => {
    return (
        <SharedDesktopContainer
            vehicle={vehicle}
            carDataContent={<CarDataContent vehicle={vehicle} />}
            finCalcLightbox={getConfig("hasFinCalcLightbox") ? <DesktopFinCalcLightbox vehicle={vehicle} /> : undefined}
        />
    );
};

export default DesktopContainer;
