import * as Chevron from "@src/shared/assets/images/chevron.inline.svg";
import * as BaseDownloadIcon from "@src/shared/assets/images/download.inline.svg";
import * as GeoIcon from "@src/shared/assets/images/location.inline.svg";
import * as ShareIcon from "@src/shared/assets/images/share.inline.svg";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled, { keyframes } from "styled-components";

const rotateAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const fadeInLoadingAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.6;
    }
`;

export const ImageContentSection = styled.div`
    width: 100%;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    position: relative;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 50%;
    }
`;

export const CarDetailHeadlineMobile = styled.div`
    padding: 0 20px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const CarTitle = styled.h1`
    margin-bottom: 0;
    font-size: 26px;
    line-height: 30px;
    margin-top: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const Geolocation = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;
    margin-right: 30px;
    margin-bottom: 20px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 0;
    }
`;

export const GeolocationIcon = styled(GeoIcon)`
    width: 15px;
    height: 15px;
    vertical-align: top;
    margin-right: 5px;
    margin-top: 6px;
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const Image = styled.img`
    width: 100%;
`;

export const Images = styled.div`
    margin-left: -20px;
    margin-right: -20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-left: 0;
        margin-right: 0;
    }
`;

export const ContentContainer = styled.div`
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: calc(50% - 30px);
        padding: 0;
        margin-left: 60px;
    }
`;

export const ResidualLinks = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px 20px;
    align-items: center;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
        gap: 20px;
    }
`;

export const DownloadIcon = styled(BaseDownloadIcon)`
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 5px;
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const ChevronIcon = styled(Chevron)`
    width: 12px;
    height: 12px;
    margin-right: 5px;
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
    min-width: 12px;
`;

export const CarDataLink = styled(AnchorLink)`
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    display: none;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: flex;
        align-items: center;
        position: relative;
        flex: 1 1 auto;
    }
`;

export const FinCalculator = styled(AnchorLink)`
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    display: none;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: flex;
        align-items: center;
        position: relative;
        flex: 1 1 auto;
    }
`;

export const QuickCheckLink = styled(AnchorLink)`
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    display: none;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        display: flex;
        align-items: center;
        position: relative;
        flex: 1 1 auto;
    }
`;

export const MobileBorder = styled.div`
    border-bottom: 10px solid ${({ theme }) => theme.colors.borderGray};
    width: auto;
    margin-left: -20px;
    margin-right: -20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const PDFLink = styled.button`
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-family: ${({ theme }) => theme.fonts.markW1G};
    text-decoration: none;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;

    :focus {
        outline: none;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex: 1 1 auto;
    }
`;

export const ShareButtonMobileContainer = styled.div`
    align-self: flex-end;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const StyledShareIcon = styled(ShareIcon)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const Loading = styled.div`
    z-index: 1;
    content: "";
    background: none;
    display: block;
    position: relative;
    width: 30px;
    height: 15px;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 15px;
        width: 15px;
        animation: ${rotateAnimation} 0.8s infinite linear, ${fadeInLoadingAnimation} 2s linear;
        border: 2px solid ${({ theme }) => theme.colors.typo};
        border-right-color: transparent;
        border-radius: 50%;
        opacity: 0.8;
        margin: 0 auto;
        bottom: -2px;
    }
`;

export const StyledLink = styled.a`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const GeolocationWrapper = styled.div`
    margin: 0 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0;
    }
`;

export const DealerClosureButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        flex-direction: row;
        justify-content: flex-start;
        gap: 15px;
    }
`;
