import { getConfig } from "@src/config";
import { GQLFinanceType, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { IFilter } from "@src/shared/context/searchfilter/IFilter";

import { OfferViewEvent } from "./types";

const buildOfferViewEvent = (vehicle: GQLVehicleDetailQuery["vehicle"], filter: IFilter) => {
    const event: OfferViewEvent = {
        event: getConfig("scope").domain === "lease-me.porschebank.at" ? "leaseme-offer-view" : "car4me-offer-view",
        financingPayment: (filter.financeType === GQLFinanceType.credit ? vehicle.creditRate : vehicle.leasingRate) ?? -1, //TODO: Umbau auf die HU Finance Types
        carCode8: vehicle.modelCode ?? "N/A",
        carBrand: vehicle.brand ?? "N/A",
        carModel: vehicle.model ?? "N/A",
        carModelGroup: vehicle.modelGroup ?? "N/A",
        carModelPriceNet: vehicle.netListPrice ?? -1,
        carCategory: vehicle.bodyType?.fmt ?? "N/A",
        carMileage: vehicle.mileage ?? -1,
        carRegistration: vehicle.firstRegistration ?? "N/A",
        dealerName: vehicle.dealer?.name ?? "N/A",
        dealerId: vehicle.dealer?.bnr ?? -1,
        dealerPostcode: vehicle.dealer?.zip ?? "N/A",
        dealerLocation: vehicle.dealer?.city ?? "N/A",
    };

    return event;
};

export default buildOfferViewEvent;
