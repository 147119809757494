import * as Chevron from "@src/shared/assets/images/chevron.inline.svg";
import { Link } from "gatsby";
import styled from "styled-components";

export const HeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLightGray};
        height: 80px;
        font-size: 18px;
        line-height: 30px;
        margin-top: 0;
        margin-bottom: 0;
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    display: flex;
    align-items: center;
`;

export const StyledLinkMobile = styled(StyledLink)`
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const StyledLinkDesktop = styled(StyledLink)`
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
    }
`;

export const LinkVehicle = styled.span`
    display: none;
    padding-left: 4px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
    }
`;

export const ChevronLeft = styled(Chevron)`
    transform: rotate(180deg);
    margin-right: 5px;
`;

export const ChevronRight = styled(Chevron)`
    margin-left: 5px;
`;

export const LastNextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const PrevVehicle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const NextVehicle = styled.div`
    position: relative;
    padding-left: 10px;
    display: flex;
    align-items: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding-left: 40px;
    }
`;
