import styled from "styled-components";

export const CarDataContent = styled.div`
    font-weight: 300;
    width: 100%;
    padding: 0;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
        padding: 0;
        border: none;
    }
`;

export const CarDataContainer = styled.div`
    flex-direction: column;
    width: 100%;
    display: flex;
`;

export const CarDataRow = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        gap: 20px;
        flex-direction: row;
    }
`;
