import SingleChoiceFilter from "@src/shared/searchResults/searchfilter/filter/singlechoicefilter/SingleChoiceFilter";
import * as React from "react";
import { Field } from "react-final-form";

import { ReactSelectOptions } from "../../../../types";
import * as sc from "./DateSelect.sc";

export interface IDateSelectProps {
    value: string;
    name: string;
}

export interface IDateSelectOptions {
    value: string;
    label: string;
}

export interface IDateSelectState {
    day?: string;
    month?: string;
    year?: string;
}

const DateSelect: React.FunctionComponent<IDateSelectProps & IDateSelectState> = ({ value, name }) => {
    const [state, setState] = React.useState<IDateSelectState>({});

    function getYearSelectOptions(): ReactSelectOptions<any>[] {
        const years: ReactSelectOptions<string>[] = [];
        const date = new Date();
        for (let i = date.getFullYear() - 18; i >= 1940; i--) {
            years.push({
                value: String(i),
                label: String(i),
            });
        }
        return years as Array<ReactSelectOptions<any>>;
    }

    function getMonthSelectOptions(): ReactSelectOptions<any>[] {
        const months: ReactSelectOptions<any>[] = [];
        for (let i = 1; i <= 12; i++) {
            months.push({
                value: i < 10 ? "0" + i : String(i),
                label: i < 10 ? "0" + i : String(i),
            });
        }
        return months as Array<ReactSelectOptions<any>>;
    }

    function getDaySelectOptions(): ReactSelectOptions<any>[] {
        const days: Array<ReactSelectOptions<any>> = [];
        for (let i = 1; i <= 31; i++) {
            days.push({
                value: i < 10 ? "0" + i : String(i),
                label: i < 10 ? "0" + i : String(i),
            });
        }

        return days as Array<ReactSelectOptions<any>>;
    }

    const onChange = async (type: any, option: any, input: any) => {
        const newDateValue = { ...state, [type]: option };
        await setState(newDateValue);

        const { day, month, year } = newDateValue;
        input.onChange(`${year}-${month}-${day}`);
    };

    const validate = (value: any): any => {
        if (value === "") return;
        return value.split("-").map((datePart: any) => (isNaN(datePart) ? "This is not a valid date" : undefined));
    };

    return (
        <sc.DateSelectWrapper>
            <Field
                name={name}
                initialValue={value}
                validate={validate}
                render={({ input }) => (
                    <>
                        <SingleChoiceFilter
                            {...input}
                            key="daySelect"
                            onChange={(option) => {
                                onChange("day", option, input);
                            }}
                            options={getDaySelectOptions()}
                            placeholder={"Tag"}
                            value={getDaySelectOptions().find((option) => option.value === input.value.split("-")[2])?.value}
                        />

                        <SingleChoiceFilter
                            {...input}
                            key="monthSelect"
                            value={getMonthSelectOptions().find((option) => option.value === input.value.split("-")[1])?.value}
                            onChange={(option) => onChange("month", option, input)}
                            options={getMonthSelectOptions()}
                            placeholder={"Monat"}
                        />

                        <SingleChoiceFilter
                            {...input}
                            key="yearSelect"
                            value={getYearSelectOptions().find((option) => option.value === input.value.split("-")[0])?.value}
                            options={getYearSelectOptions()}
                            placeholder={"Jahr"}
                            onChange={(option) => onChange("year", option, input)}
                        />
                    </>
                )}
            />
        </sc.DateSelectWrapper>
    );
};

export default DateSelect;
