import * as React from "react";
import { Field } from "react-final-form";

import * as sc from "./ShowField.sc";

export interface IShowFieldProps {
    name: string;
    value: string | boolean;
    valueFromAttribute?: string;
    errorMessage?: string;
}

const ShowField: React.FunctionComponent<IShowFieldProps> = ({ name, value, valueFromAttribute, errorMessage }) => (
    <div>
        {valueFromAttribute && <Field component="input" type="hidden" name={name} initialValue={valueFromAttribute} />}
        <Field name={name} value={value} component="input">
            {() => value}
        </Field>
        {errorMessage && <sc.Error>{errorMessage}</sc.Error>}
    </div>
);

export default ShowField;
