import styled, { keyframes } from "styled-components";

export const Root = styled.div`
    margin: 0;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0 30px;
    }
`;

export const Loading = styled.div`
    min-height: 100vh;
    padding: 20px;
`;

const rotateAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoadingIcon = styled.div`
    height: 50px;
    width: 50px;
    margin: 0 auto;
    animation: ${rotateAnimation} 0.8s infinite linear;
    border: 5px solid ${({ theme }) => theme.colors.porscheBankBlue};
    border-right-color: transparent;
    border-radius: 50%;
`;
