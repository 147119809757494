import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import FinCalcLightbox from "@src/RO/finCalcLightbox/FinCalcLightbox";
import CarDataContent from "@src/shared/vehicleDetail/carDataContent/CarDataContent";
import Dropdown from "@src/shared/vehicleDetail/mobile/Dropdown";
import SharedMobileDropdown from "@src/shared/vehicleDetail/mobile/MobileDropdown";
import * as sc from "@src/shared/vehicleDetail/mobile/MobileDropdown.sc";
import React from "react";
import { FormattedMessage } from "react-intl";

interface MobileDropdownProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({ vehicle }) => {
    const [isOpen, setIsOpen] = React.useState({
        finCalcLightbox: false,
    });

    const onFinCalcLightboxDropdownClicked = () => {
        setIsOpen({ ...isOpen, finCalcLightbox: !isOpen.finCalcLightbox });
    };

    return (
        <SharedMobileDropdown vehicle={vehicle} carDataContent={<CarDataContent vehicle={vehicle} />}>
            <Dropdown
                header={
                    <div>
                        <sc.HorizontalLine />
                        <sc.DropdownHeadline>
                            <FormattedMessage id="leaseme.finCalcLeasing" defaultMessage="Leasingfinanzierung klassisch" />
                        </sc.DropdownHeadline>
                    </div>
                }
                open={true}
                onHeaderClick={onFinCalcLightboxDropdownClicked}
                hasNoIcon
            >
                <FinCalcLightbox />
            </Dropdown>
        </SharedMobileDropdown>
    );
};

export default MobileDropdown;
