import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import * as GeoIcon from "@src/shared/assets/images/location.inline.svg";
import Button, { ButtonSize, ButtonVariant } from "@src/shared/button/Button";
import { ChevronIcon } from "@src/skeleton/vehicleDetail/imageContentSection/ImageContentSection.sc";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface DealerButtonProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    onClick: () => void;
}
export const DealerButton = ({ vehicle, onClick }: DealerButtonProps) => {
    return (
        <>
            {vehicle.dealer?.zip && vehicle.dealer?.city && vehicle.vehicleLink && (
                <DealerContactButton>
                    {getConfig("scope").domain !== "lease-me.porschebank.at" && (
                        <Geolocation>
                            <GeolocationIcon /> {vehicle.dealer?.zip} {vehicle.dealer?.city}
                        </Geolocation>
                    )}
                    <Button size={ButtonSize.Default} variant={ButtonVariant.Primary} onClick={onClick} data-gtm-event="dealer-contact-form-link">
                        <DealerButtonText>
                            <FormattedMessage id="leaseme.dealer.contact" defaultMessage="Händler kontaktieren" />
                        </DealerButtonText>
                    </Button>
                    {getConfig("hasDwa") && (
                        <DWALink href={vehicle.vehicleLink || "#"} target="_blank">
                            <FormattedMessage id="leaseme.goToDwa" defaultMessage="Zu DWA" />
                            <ChevronRight />
                        </DWALink>
                    )}
                </DealerContactButton>
            )}
        </>
    );
};

const DealerContactButton = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 20px 0;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin: 0 0 30px 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
`;

const Geolocation = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;
    margin-right: 30px;
    margin-bottom: 20px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin-bottom: 0;
    }
`;

const GeolocationIcon = styled(GeoIcon)`
    width: 15px;
    height: 15px;
    vertical-align: top;
    margin-right: 5px;
    margin-top: 6px;
    fill: ${({ theme }) => theme.colors.porscheBankBlue};
`;

const DealerButtonText = styled.div`
    text-transform: uppercase;
`;

const DWALink = styled.a`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 20px;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin: 0;
        margin-left: 30px;
        padding: 10px 0;
    }
`;

const ChevronRight = styled(ChevronIcon)`
    width: 15px;
    height: 15px;
    min-width: 15px;
    margin-left: 5px;
`;
