import styled from "styled-components";

export const MobileDropdownContainer = styled.div`
    margin-bottom: 70px; // Damit der Fixed Button nicht den untersten Content verdeckt
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const DropdownHeadline = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0;
    padding-top: 10px;
    font-weight: 700;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 40px;
        padding: 0;
    }
    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0;
    }
`;

export const CarDataEquipmentWrapper = styled.div`
    padding: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 20px 0;
        box-sizing: border-box;
    }

    ${({ theme }) => theme.breakpoints.xl.mediaquery} {
        padding: 0;
    }
`;
