import { Text } from "@react-pdf/renderer";
import { GQLPriceInfoFragment } from "@src/graphql.apollo.generated";
import React from "react";

import { disclaimerStyles } from "./DisclaimerStyles";

interface DisclaimerProps {
    priceInfo?: GQLPriceInfoFragment;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ priceInfo }) => {
    const disclaimer = priceInfo?.finCalcData?.disclaimer;

    if (!disclaimer) {
        return null;
    }

    return <Text style={disclaimerStyles.disclaimerText}>** {disclaimer}</Text>;
};

export default Disclaimer;
