import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const disclaimerStyles = StyleSheet.create({
    disclaimerText: {
        padding: "5mm 0",
        width: "100%",
        fontSize: "8pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
    },
});
