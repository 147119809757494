import { GQLFinanceType, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import InfoIcon from "@src/shared/infoIcon/InfoIcon";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import * as sc from "./PriceInfoValues.sc";

interface IPriceInfoValuesProps {
    visibleTab: GQLFinanceType;
    data: GQLVehicleDetailQuery["vehicle"];
}

const PriceInfoValues: React.FunctionComponent<IPriceInfoValuesProps> = ({ visibleTab, data }) => {
    const { filter } = useSearchFilter();
    const intl = useIntl();

    const priceInfo = data.finCalcData;

    return (
        <>
            <sc.ContentValues>
                <sc.ResidualValue>
                    <sc.ResidualValueTitle>
                        {visibleTab === GQLFinanceType.leasing ? (
                            <FormattedMessage id="leaseme.priceInfo.residualValue.leasing" defaultMessage="Restwert-Leasing" />
                        ) : (
                            <FormattedMessage id="leaseme.priceInfo.residualValue.credit" defaultMessage="Restwert-Kredit" />
                        )}
                    </sc.ResidualValueTitle>
                    <sc.AmountTextContainer>
                        <sc.Amount>
                            <FormattedCurrency value={priceInfo?.rate} maximumFractionDigits={2} minimumFractionDigits={2} /> *
                        </sc.Amount>
                        <sc.ResidualValueText>
                            <FormattedMessage id="leaseme.perMonth" defaultMessage="pro Monat" />
                        </sc.ResidualValueText>
                    </sc.AmountTextContainer>
                </sc.ResidualValue>
                <sc.Values isCredit={visibleTab === GQLFinanceType.credit}>
                    <sc.ValueHeader>
                        <FormattedMessage
                            id="leaseme.runtime.value"
                            defaultMessage="{financeRuntime} Monate"
                            values={{
                                financeRuntime: intl.formatNumber(filter.financeRuntime),
                            }}
                        />
                    </sc.ValueHeader>
                    <sc.ValueHeader>
                        <FormattedCurrency value={priceInfo?.downPayment} />
                    </sc.ValueHeader>
                    <sc.ValueContent>
                        {" "}
                        <FormattedMessage id="leaseme.runtime.label" defaultMessage="Laufzeit" />
                    </sc.ValueContent>
                    <sc.ValueContent>
                        <ValueContentValuesWrapper>
                            <FormattedMessage id="leaseme.downPayment.label" defaultMessage="Eigenleistung" />
                            {visibleTab === GQLFinanceType.leasing ? (
                                <InfoIcon
                                    alignment="right"
                                    infoText={intl.formatMessage({
                                        id: "leaseme.downPayment.message.leasing",
                                        defaultMessage:
                                            "Die Eigenleistung ist hier ein vorzeitig rückzahlbares Depot. Das heißt, dass die Einmalzahlung auf die vereinbarte Laufzeit aufgeteilt wird. Damit verringert sich das monatliche Leasingentgelt. Die Eigenleistung darf maximal 50% des Fahrzeugpreises netto betragen.",
                                    })}
                                />
                            ) : (
                                <InfoIcon
                                    alignment="right"
                                    infoText={intl.formatMessage({
                                        id: "leaseme.downPayment.message.credit",
                                        defaultMessage:
                                            "Durch eine Anzahlung reduzieren sich Ihre monatlichen Raten. Möglich ist eine Anzahlung bis zu 40% des Kaufpreises.",
                                    })}
                                />
                            )}
                        </ValueContentValuesWrapper>
                    </sc.ValueContent>
                </sc.Values>
            </sc.ContentValues>
        </>
    );
};

const ValueContentValuesWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export default PriceInfoValues;
