import React from "react";
import styled from "styled-components";

interface AnchorProps {
    id: string;
}

const Anchor: React.FC<AnchorProps> = ({ id }) => {
    return <AnchorBlock id={id} />;
};

const AnchorBlock = styled.div`
    position: relative;
    display: block;
    top: -140px;
`;

export default Anchor;
