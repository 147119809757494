import { View } from "@react-pdf/renderer";
import { GQLFinanceType, GQLPriceInfoFragment } from "@src/graphql.apollo.generated";
import { IFilter } from "@src/shared/context/searchfilter/IFilter";
import { getFormattedCurrency } from "@src/shared/utils/getFormattedCurrency";
import FinancialDetailDataEntryComponent from "@src/shared/vehicleDetail/pdf/financialdetail/FinancialDetailDataEntryComponent";
import * as React from "react";
import { useIntl } from "react-intl";

import { financialDetailData } from "./FinancialDetailDataStyles";
interface IDetailsInfoProps {
    type: GQLFinanceType;
    currentPriceInfo?: GQLPriceInfoFragment;
    filter: IFilter;
}

const FinancialDetailDataComponent: React.FunctionComponent<IDetailsInfoProps> = ({ type, currentPriceInfo, filter }) => {
    const intl = useIntl();

    const priceInfo = currentPriceInfo?.finCalcData;

    return (
        <View style={financialDetailData.infoContainer}>
            <FinancialDetailDataEntryComponent
                value={intl.formatMessage(
                    {
                        id: "leaseme.runtime.value",
                        defaultMessage: "{financeRuntime} Monate",
                    },
                    {
                        financeRuntime: intl.formatNumber(filter.financeRuntime),
                    },
                )}
                description={intl.formatMessage({
                    id: "leaseme.runtime.label",
                    defaultMessage: "Laufzeit",
                })}
            />
            {type === GQLFinanceType.credit && (
                <FinancialDetailDataEntryComponent
                    value={getFormattedCurrency({ value: priceInfo?.residualValue || 0 }, intl)}
                    description={`${intl.formatMessage({
                        id: "leaseme.residualValue.label",
                        defaultMessage: "Restwert",
                    })}`}
                />
            )}
            <FinancialDetailDataEntryComponent
                value={getFormattedCurrency(
                    {
                        value: priceInfo?.downPayment || 0,
                    },
                    intl,
                )}
                description={intl.formatMessage({
                    id: "leaseme.downPayment.label",
                    defaultMessage: "Eigenleistung",
                })}
            />
        </View>
    );
};

export default FinancialDetailDataComponent;
