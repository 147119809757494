import * as React from "react";
import { Field } from "react-final-form";

export interface IHiddenProps {
    name: string;
    value?: string;
    updateFinCalcFormValue?: (key: string, value: any) => void;
}

const Hidden: React.FunctionComponent<IHiddenProps> = ({ name, value, updateFinCalcFormValue }) => {
    React.useEffect(() => {
        if (updateFinCalcFormValue) {
            updateFinCalcFormValue(name, value);
        }
    }, [name, value]);

    return <div>{value && <Field component="input" type="hidden" name={name} initialValue={value} />}</div>;
};

export default Hidden;
