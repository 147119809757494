import { LocationContext } from "@src/shared/context/LocationContext";
import { SnackbarType } from "@src/shared/context/snackbar/SnackbarContext";
import useSnackbar from "@src/shared/context/snackbar/useSnackbar";
import * as sc from "@src/shared/vehicleDetail/shareMenu/ShareMenu.sc";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const ShareMenuItems: React.FunctionComponent = () => {
    const intl = useIntl();
    const { location } = React.useContext(LocationContext);
    const { showSnackbar } = useSnackbar();

    function handleCopyLinkClick() {
        if (location) {
            navigator.clipboard.writeText(location.href);
            showSnackbar(
                SnackbarType.info,
                intl.formatMessage({
                    id: "leaseme.share.copy.info",
                    defaultMessage: "Link wurde kopiert",
                }),
            );
        }
    }

    const shareText = intl.formatMessage({
        id: "leaseme.share.text",
        defaultMessage: "Sieh dir an was ich auf Lease Me gefunden habe...",
    });
    const shareSubject = intl.formatMessage({
        id: "leaseme.share.subject",
        defaultMessage: "Sieh dir an was ich auf Lease Me gefunden habe...",
    });
    const shareLink = location?.href ? location.href : "";
    const shareContent = encodeURI(`${shareText} ${shareLink}`);

    return (
        <>
            <sc.ShareMenuItem href={`mailto:?subject=${shareSubject}&body=${shareText}%0D%0A${encodeURIComponent(shareLink)}`}>
                <sc.StyledMailIcon />
                <FormattedMessage id="leaseme.share.email" defaultMessage="E-Mail" />
            </sc.ShareMenuItem>
            <sc.ShareMenuItem href={`https://wa.me/?text=${shareContent}`} data-action="share/whatsapp/share" target="_blank">
                <sc.StyledWhatsAppIcon />
                <FormattedMessage id="leaseme.share.whatsapp" defaultMessage="WhatsApp" />
            </sc.ShareMenuItem>
            <sc.ShareMenuItem
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(shareLink)}&quote=${shareContent}`}
                rel="noopener"
                target="_blank"
            >
                <sc.StyledFacebookIcon />
                <FormattedMessage id="leaseme.share.facebook" defaultMessage="Facebook" />
            </sc.ShareMenuItem>
            <sc.ShareMenuItem href={`https://twitter.com/intent/tweet?text=${shareContent}`} target="_blank" rel="noopener">
                <sc.StyledTwitterIcon />
                <FormattedMessage id="leaseme.share.twitter" defaultMessage="Twitter" />
            </sc.ShareMenuItem>
            <sc.ShareMenuItem onClick={handleCopyLinkClick}>
                <sc.StyledLinkIcon />
                <FormattedMessage id="leaseme.share.copy" defaultMessage="Link kopieren" />
            </sc.ShareMenuItem>
        </>
    );
};

export default ShareMenuItems;
