import { GQLBoniCheckParameterFragment } from "@src/graphql.apollo.generated";
import { IResponseGroup } from "@src/shared/utils/httpClient";
import * as React from "react";

import Row from "./Row";
import * as sc from "./Section.sc";

export interface IComponentProps {
    data: IResponseGroup;
    errors?: GQLBoniCheckParameterFragment["errors"] | null;
    updateFinCalcFormValue?: (key: string, value: any) => void;
}

const Section: React.FunctionComponent<IComponentProps> = ({ data, errors, updateFinCalcFormValue }) => (
    <sc.Wrapper data={data}>
        {data.label && <sc.Headline>{data.label}</sc.Headline>}
        {data.disclaimer && <sc.Disclaimer dangerouslySetInnerHTML={{ __html: data.disclaimer || "" }} />}
        {data.parameters.map((parameter: any) => (
            <Row key={parameter.id} parameter={parameter} errors={errors} updateFinCalcFormValue={updateFinCalcFormValue} />
        ))}
    </sc.Wrapper>
);

export default Section;
