import ReactPDF from "@react-pdf/renderer";
import { GQLFinanceInformation, GQLFinanceType, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import CustomIntlProvider from "@src/skeleton/intlProvider/CustomIntlProvider";
import LeaseMePdf from "@src/skeleton/vehicleDetail/pdf/LeaseMePdf";
import * as React from "react";

import { IFilter } from "../context/searchfilter/IFilter";

export default async function getVehicleDataBlob(
    vehicle: GQLVehicleDetailQuery["vehicle"],
    type: GQLFinanceType,
    filter: IFilter,
    financeInformation?: GQLFinanceInformation,
) {
    return await ReactPDF.pdf(
        <CustomIntlProvider>
            <LeaseMePdf vehicle={vehicle} type={type} filter={filter} financeInformation={financeInformation} />
        </CustomIntlProvider>,
    ).toBlob();
}
