import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import { useIntl } from "react-intl";
import styled from "styled-components";

export interface InputProps extends FieldRenderProps<string, HTMLInputElement> {
    maxLength: number;
    hasError: boolean;
}

const Input: React.FunctionComponent<InputProps> = ({ maxLength, hasError, input: { value, onChange, type } }) => {
    const [internalValue, setInternalValue] = React.useState(value);
    const [isEditing, setIsEditing] = React.useState(false);
    const intl = useIntl();

    React.useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const onBlur = (target: EventTarget & HTMLInputElement) => {
        if (type === "number") {
            const value = Math.round(Number(target.value));
            onChange(!!value && Math.abs(value) >= 0 ? Math.abs(value) : 0); //only accept positive values
        } else {
            onChange(target.value);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.currentTarget.blur();
            onBlur(event.currentTarget);
        }
    };

    return (
        <Root>
            <StyledInput
                type={isEditing ? type : "text"}
                value={isEditing ? internalValue : intl.formatNumber(Number(internalValue))}
                onFocus={() => setIsEditing(true)}
                maxLength={maxLength}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInternalValue(event.target?.value)}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    setIsEditing(false);
                    onBlur(event.target);
                }}
                onKeyDown={handleKeyDown}
                hasError={hasError}
                min={0}
            />
        </Root>
    );
};

const Root = styled.div`
    position: relative;
    height: 40px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
`;

interface StyledInputProps {
    hasError: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.darkRed : theme.colors.borderLightGray)};
    border-radius: 1px;
    padding: 0 15px;
    font-size: 14px;
    color: ${({ theme, hasError }) => (hasError ? theme.colors.darkRed : theme.colors.porscheBankBlue)};
    font-family: ${({ theme }) => theme.fonts.markW1G};
`;

export default Input;
