import styled from "styled-components";

export const CarTitleContainer = styled.div`
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 80px 0 40px;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const CarTitle = styled.h1`
    margin-bottom: 0;
    font-size: 26px;
    line-height: 30px;
    margin-top: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    display: flex;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const ShareButtonDesktopContainer = styled.div`
    display: none;
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
        margin-top: 19px;
    }
`;
