import * as ChevronDown from "@src/shared/assets/images/chevron_down.inline.svg";
import styled, { css } from "styled-components";

interface TitleWrapperProps {
    open: boolean;
}

export const TitleWrapper = styled.div<TitleWrapperProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: ${({ theme, open }) => (open ? "none" : `1px solid ${theme.colors.backgroundLightGray}`)};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 20px;
    }
`;

export const Title = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-weight: 700;
        font-size: 20px;
    }
`;

export const Text = styled.div`
    font-size: 16px;
    margin: 0;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.backgroundLightGray};
`;

export const OpeningTimesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.backgroundLightGray};
    padding: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;
    }
`;

export const OpeningTimeContainer = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
    }
`;

export const OpeningDay = styled.div`
    margin-bottom: 5px;
`;

export const OpeningTime = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OpenCloseIcon = styled(ChevronDown)`
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    width: 20px;
    height: 14px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    transform: rotate(0deg);

    ${({ open }) =>
        open &&
        css`
            transform: rotate(180deg);
        `}
`;
