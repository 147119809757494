import { getConfig } from "@src/config";
import VehicleDetail from "@src/skeleton/vehicleDetail/VehicleDetail";
import { graphql, navigate, PageProps } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";
import { useIntl } from "react-intl";

interface DetailProp extends PageProps {
    params: {
        "*": string; // vehicleId
    };
}
const Detail: React.FunctionComponent<DetailProp> = ({ params }) => {
    const intl = useIntl();
    const scope = getConfig("scope");
    const vehicleId = params["*"];

    React.useEffect(() => {
        if (!vehicleId && typeof window === "object") {
            navigate("/404");
        }
    }, [vehicleId]);

    let title: string;

    if (scope.domain === "lease-me.porschebank.at") {
        title = intl.formatMessage({ id: "lease-me.detail.htmlTitle", defaultMessage: "Auto Leasing Angebote | Lease Me - Porsche Bank" });
    } else {
        // Todo add right title for Car4me
        title = intl.formatMessage({ id: "car4me.detail.htmlTitle", defaultMessage: "Car4me" });
    }

    return (
        <>
            <Helmet meta={[{ name: "robots", content: "noindex" }]} title={title} />
            {vehicleId && <VehicleDetail vehicleId={vehicleId} />}
        </>
    );
};

export default Detail;

export const query = graphql`
    query OfferPage($leasemeScope: LeaseMe_ContentScopeInput!) {
        leaseme {
            allVehicles(scope: $leasemeScope) {
                facets {
                    ...AllFacets
                }
            }
        }
    }
`;
