import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const titleComponentStyles = StyleSheet.create({
    title: {
        color: theme.colors.porscheBankBlue,
        fontFamily: "MarkW1G",
        fontSize: "18pt",
        fontWeight: "bold",
        marginTop: "11pt",
    },
    horizontalLine: {
        width: "80pt",
        height: "3pt",
        backgroundColor: theme.colors.darkRed,
    },
});
