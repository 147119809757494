import * as ChevronDown from "@src/shared/assets/images/chevron_down.inline.svg";
import styled from "styled-components";

interface CarDataContentProps {
    showMore: boolean;
}

export const CarDataContent = styled.div<CarDataContentProps>`
    font-weight: 300;
    width: 100%;
    padding: 0;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    position: relative;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
        border: none;

        height: ${({ showMore }) => (showMore ? "initial" : "240px")};
        overflow: ${({ showMore }) => (showMore ? "none" : "hidden")};

        :after {
            display: ${({ showMore }) => (showMore ? "none" : "block")};
            position: absolute;
            content: "";
            left: 0px;
            top: 0px;
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 75%, white 100%);
        }
    }
`;

export const CarDataContainer = styled.div`
    flex-direction: column;
    width: 100%;
    display: flex;
`;

export const CarDataRow = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        gap: 20px;
        flex-direction: row;
    }
`;

export const MoreButton = styled.div`
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    justify-content: center;
    bottom: 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
    }
`;

export const LessButton = styled.div`
    display: none;
    padding-top: 15px;
    width: 100%;
    justify-content: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
    }
`;

export const ButtonText = styled.button`
    width: 200px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 16px;
    line-height: 22px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ShowMoreChevron = styled(ChevronDown)`
    width: 15px;
    height: 15px;
    margin-right: 10px;
`;

export const ShowLessChevron = styled(ChevronDown)`
    transform: rotate(180deg);
    width: 15px;
    height: 15px;
    margin-right: 10px;
`;
