import styled from "styled-components";

export const Tabs = styled.div`
    text-align: center;
    margin: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
    display: flex;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        text-align: left;
        margin: 0 0 20px 0;
    }
`;

interface ITabProps {
    active: boolean;
}

export const Tab = styled.button<ITabProps>`
    cursor: pointer;
    flex-basis: 50%;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-align: center;
    font-size: 16px;
    font-weight: ${({ active }) => (active ? 700 : 400)};
    letter-spacing: 0;
    line-height: 22px;
    padding: 9px 0;
    border-bottom: 2px solid ${({ active, theme }) => (active ? theme.colors.porscheBankBlue : "transparent")};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 18px;
        line-height: 27px;
        margin: 0;
    }
`;
