import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import VehicleDataContainer from "@src/shared/vehicleDetail/carDataContent/VehicleDataContainer";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./CarDataContent.sc";

interface CarDataContentProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const CarDataContent: React.FC<CarDataContentProps> = ({ vehicle }) => {
    const intl = useIntl();

    const [isExpanded, setIsExpanded] = React.useState(false);

    const handleExpandableButton = () => {
        setIsExpanded(!isExpanded);
    };

    const emissionLabel = (
        <>
            <FormattedMessage id="leaseme.emission" defaultMessage={"CO\u2082-Emission"} />
        </>
    );

    return (
        <sc.CarDataContent showMore={isExpanded}>
            <sc.CarDataContainer>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.color",
                            defaultMessage: "Farbe",
                        })}
                        value={vehicle.bodyColor?.text ?? "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.mileage",
                            defaultMessage: "Kilometerstand",
                        })}
                        value={
                            vehicle.mileage
                                ? `${intl.formatNumber(vehicle.mileage)} ${intl.formatMessage({
                                      id: "leaseme.km",
                                      defaultMessage: "km",
                                  })} ${getConfig("scope").domain === "lease-me.porschebank.at" ? "*" : ""}`
                                : "-"
                        }
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.firstRegistration",
                            defaultMessage: "Erstzulassung",
                        })}
                        value={vehicle?.firstRegistration ? format(parseISO(vehicle.firstRegistration), "MM/yyyy") : "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.power",
                            defaultMessage: "Leistung",
                        })}
                        value={
                            vehicle.powerPS
                                ? intl.formatMessage(
                                      {
                                          id: "leaseme.powerValue",
                                          defaultMessage: "{powerPS} PS",
                                      },
                                      {
                                          powerPS: vehicle.powerPS,
                                      },
                                  )
                                : "-"
                        }
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.fuelConsumption",
                            defaultMessage: "Komb. Kraftstoffverbr.",
                        })}
                        value={vehicle.fuel?.consumption?.fmt ?? "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.fuelType",
                            defaultMessage: "Kraftstoffart",
                        })}
                        value={vehicle.fuel?.text ?? "-"}
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={emissionLabel}
                        value={vehicle.fuel?.co2?.fmt && vehicle.fuel?.co2?.fmt.length > 0 ? vehicle.fuel?.co2?.fmt : "-"}
                        infoIconText={
                            getConfig("scope").domain === "car4me.bg" && vehicle.fuel?.co2?.fmt && vehicle.fuel?.co2?.fmt.length > 0 ? (
                                <FormattedMessage
                                    id="leaseme.co2.disclaimer"
                                    defaultMessage="EU-Angaben zum Kraftstoffverbrauch und zu den CO2-Emissionen gemäß der Verordnung. (EG) 715/2007. Die Angaben beziehen sich nicht auf ein einzelnes Fahrzeug und sind nicht Bestandteil des Angebots, sondern dienen allein Vergleichszwecken zwischen verschiedenen Fahrzeugtypen. Insbesondere Zusatzausstattungen und Zubehör (z. B. breitere Reifen, Klimaanlage, Dachgepäckträger usw.) können zu unterschiedlichen Kraftstoffverbrauchs- und CO2-Emissionswerten führen."
                                />
                            ) : undefined
                        }
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.offerRefference",
                            defaultMessage: "Bestandsnr.",
                        })}
                        value={vehicle.offerReference || "-"}
                    />
                </sc.CarDataRow>

                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.driveType",
                            defaultMessage: "Antrieb",
                        })}
                        value={vehicle.driveType?.fmt ?? "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.doors",
                            defaultMessage: "Türen",
                        })}
                        value={vehicle.doors?.toString() ?? "-"}
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.seats",
                            defaultMessage: "Sitze",
                        })}
                        value={vehicle.seats?.toString() ?? "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.body",
                            defaultMessage: "Karosserie",
                        })}
                        value={vehicle.bodyType?.fmt ?? "-"}
                    />
                </sc.CarDataRow>
                <sc.CarDataRow>
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.engineDisplacement",
                            defaultMessage: "Hubraum",
                        })}
                        value={vehicle.cylinderCapacity?.fmt ?? "-"}
                    />
                    <VehicleDataContainer
                        label={intl.formatMessage({
                            id: "leaseme.transmission",
                            defaultMessage: "Getriebeart",
                        })}
                        value={vehicle.transmission?.fmt ?? "-"}
                    />
                </sc.CarDataRow>
            </sc.CarDataContainer>
            {isExpanded ? (
                <sc.LessButton>
                    <sc.ButtonText onClick={handleExpandableButton}>
                        <sc.ShowLessChevron />
                        <FormattedMessage id="leaseme.showLess" defaultMessage="Weniger anzeigen" />
                    </sc.ButtonText>
                </sc.LessButton>
            ) : (
                <>
                    <sc.MoreButton>
                        <sc.ButtonText onClick={handleExpandableButton}>
                            <sc.ShowMoreChevron />
                            <FormattedMessage id="leaseme.showMore" defaultMessage="Mehr anzeigen" />
                        </sc.ButtonText>
                    </sc.MoreButton>
                </>
            )}
        </sc.CarDataContent>
    );
};

export default CarDataContent;
