import styled from "styled-components";

export const Loading = styled.div`
    z-index: 1;
    content: "";
    position: relative;
    top: 50px;
    left: 0;
    right: 0;
    margin-bottom: 150px;
    height: 100px;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 46px;
        width: 46px;
        animation: rotate 0.8s infinite linear, fadeInLoading 2s linear;
        border: 2px solid ${({ theme }) => theme.colors.typo};
        border-right-color: transparent;
        border-radius: 50%;
        opacity: 0.6;
        left: 0;
        right: 0;
        top: 25px;
        margin: 0 auto;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes fadeInLoading {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.6;
        }
    }
`;
