import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const financialDetailTitleStyles = StyleSheet.create({
    root: {
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "11pt",
    },
    container: {
        padding: "0 15pt",
    },
    subTitle: {
        color: theme.colors.porscheBankBlue,
        fontFamily: "MarkW1G",
        fontSize: "9pt",
        paddingBottom: "2pt",
    },
    rateHeadline: {
        color: theme.colors.porscheBankBlue,
        fontFamily: "MarkW1G",
        fontWeight: "bold",
        fontSize: "9pt",
    },
    rateSubHeadline: {
        color: theme.colors.porscheBankBlue,
        fontFamily: "MarkW1G",
        fontWeight: "normal",
        fontSize: "9pt",
    },
});
