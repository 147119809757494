import React from "react";
import { FieldRenderProps } from "react-final-form";

import { InputCircle, OptionLabel, StyledRadio } from "./RadioInput.sc";

interface RadioInputProps extends FieldRenderProps<string, HTMLInputElement> {
    hasExtendedValue: boolean;
}

export const RadioInput: React.FC<RadioInputProps> = ({ hasExtendedValue, input, children, width, ...restProps }) => {
    return (
        <OptionLabel width={width} hasExtendedValue={hasExtendedValue}>
            <StyledRadio {...input} {...restProps} />
            <InputCircle />
            {children}
        </OptionLabel>
    );
};
