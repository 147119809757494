import { Loader } from "@googlemaps/js-api-loader";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { getConfig } from "@src/config";
import theme from "@src/shared/theme/theme-porschebank";
import * as React from "react";
import styled from "styled-components";

interface Props {
    lng: number;
    lat: number;
}

export function DealerMap({ lng, lat }: Props): React.ReactElement {
    const mapRef = React.useRef<google.maps.Map>();
    const mapElementRef = React.useRef<HTMLDivElement | null>(null);
    const markerClustererRef = React.useRef<MarkerClusterer>();
    const [mapInitialized, setMapInitialized] = React.useState(false);

    React.useEffect(() => {
        const initializeMap = async () => {
            await new Loader({
                apiKey: getConfig("googleMapsApiKey")!,
                version: "weekly",
            }).load();

            mapRef.current = new google.maps.Map(mapElementRef.current!, {
                zoom: 12,
                minZoom: 4,
                maxZoom: 16,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP,
                },
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP,
                },
                mapTypeControl: false,
                streetViewControl: false,
            });

            markerClustererRef.current = new MarkerClusterer(mapRef.current, [], {
                styles: [
                    {
                        width: 45,
                        height: 45,
                        url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", //TODO change to POI marker
                        fontFamily: theme.fonts.markW1G,
                        fontWeight: "600",
                        textSize: 14,
                        textLineHeight: 45,
                    },
                ],
            });

            setMapInitialized(true);
        };

        initializeMap();
    }, []);

    React.useEffect(() => {
        if (mapRef.current && markerClustererRef.current) {
            const marker = new google.maps.Marker({
                position: {
                    lat,
                    lng,
                },
            });

            markerClustererRef.current.clearMarkers();
            markerClustererRef.current.addMarker(marker);
            markerClustererRef.current.fitMapToMarkers(0);
        }
    }, [lng, lat, mapInitialized]);

    return <Root ref={mapElementRef} />;
}

const Root = styled.div`
    height: 100%;
`;
