import styled from "styled-components";

interface OptionLabelProps {
    width?: string;
    hasExtendedValue: boolean;
}

export const OptionLabel = styled.label<OptionLabelProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-basis: 50%;
    justify-content: ${({ hasExtendedValue }) => (hasExtendedValue ? "flex-start" : "flex-end")};

    ${({ width }) => width && `width: ${width}`}
`;

export const InputCircle = styled.div`
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    box-sizing: border-box;
    margin-right: 10px;
    min-width: 20px;
`;

export const StyledRadio = styled.input`
    background: transparent;
    position: relative;
    visibility: hidden;
    margin: 0;
    padding: 0;
    display: none;

    &:checked + ${InputCircle} {
        border: 5px solid ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;
