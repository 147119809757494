import { Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { titleComponentStyles } from "./TitleComponentStyles";

interface ITitleProps {
    title?: string | null;
}

const TitleComponent: React.FunctionComponent<ITitleProps> = ({ title = "" }) => {
    const vehicleTitle: string = title + "*";
    return (
        <View>
            <View style={titleComponentStyles.horizontalLine} />
            <Text style={titleComponentStyles.title}>{vehicleTitle}</Text>
        </View>
    );
};

export default TitleComponent;
