import Images from "@src/shared/assets/images/images";
import styled, { css } from "styled-components";
import { Swiper } from "swiper/react";

import { ImageSwiperProps } from "./ImageSwiper";

export const SwiperSlider = styled(Swiper)<ImageSwiperProps>`
    && {
        .swiper-slide {
            opacity: 0;
        }

        .swiper-slide-active {
            opacity: 1;
        }

        ${({ fullWidth }) =>
            fullWidth &&
            css`
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                position: fixed;
                max-width: 70vw;
                max-height: 70vh;
                margin: auto;
                object-fit: contain;
                z-index: 1200;
                padding: 50px;

                display: flex;
                align-items: center;
            `};

        .swiper-button-prev {
            transform: rotate(0deg);
            right: auto;
            left: 30px;

            ${({ theme }) => theme.breakpoints.md.mediaquery} {
                left: 10px;
            }
        }

        .swiper-button-next {
            transform: rotate(180deg);
            left: auto;
            right: 30px;

            ${({ theme }) => theme.breakpoints.md.mediaquery} {
                right: 10px;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: ${({ fullWidth }) => (fullWidth ? "fixed" : "absolute")};
            background-image: url(${Images.LeftArrow});
            transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1), opacity 0.3s linear;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 30px 30px;
            appearance: none;
            z-index: 10;
            top: 0;
            bottom: 0;
            margin-bottom: auto;
            margin-top: auto;
            filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.3));

            @media (min-width: 256px) {
                background-size: 48px 48px;
                height: 30px;
                width: 30px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
`;

export const OverlayFullScreen = styled.div<ImageSwiperProps>`
    position: absolute;
    right: -55vw;
    left: -55vw;
    top: -55vh;
    bottom: -55vh;
    z-index: 1199;
    background-color: ${({ theme }) => `${theme.colors.black}e9`};
    visibility: ${({ fullWidth }) => (fullWidth ? "visible" : "hidden")};
    display: ${({ fullWidth }) => (fullWidth ? "block" : "none")};
    margin: 0;
`;

interface Props {
    isFullscreen: boolean;
}

export const Index = styled.div<Props>`
    position: ${({ isFullscreen }) => (isFullscreen ? "fixed" : "absolute")};
    bottom: 20px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    background-color: ${({ theme }) => `${theme.colors.black}66`};
    color: ${({ theme }) => `${theme.colors.white}`};
    padding: 5px 12px;
    border-radius: 2px;
    font-size: 16px;
    line-height: 22px;
`;

export const FullScreenButton = styled.div<Props>`
    position: absolute;
    top: 10px;
    right: 30px;
    background-position: center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-image: url(${Images.Expand});
    z-index: 50;
    height: 48px;
    width: 48px;
    cursor: pointer;
    filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.3));

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        right: 10px;
    }
`;

export const CloseFullScreenButton = styled.div<Props>`
    position: fixed;
    top: 10px;
    right: 10px;
    background-position: center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-image: url(${Images.Close});
    z-index: 1250;
    height: 48px;
    width: 48px;
    cursor: pointer;
    filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.3));

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        right: 10px;
    }
`;

export const SwiperRoot = styled.div<Props>`
    ${({ isFullscreen }) =>
        isFullscreen &&
        css`
            position: relative;
            cursor: grab;
            display: flex;
            height: 500px;
            overflow: hidden;

            :active {
                cursor: grabbing;
            }
        `}
`;

export const ImageWrapper = styled.div<Props>`
    ${({ isFullscreen }) =>
        isFullscreen
            ? css`
                  position: relative;
                  overflow: hidden;
                  height: 100%;
                  width: 100%;
              `
            : css`
                  padding-bottom: 75%;
              `}
`;
