import styled from "styled-components";

export const DateSelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: 10px;
        width: 100%;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        flex-direction: row;
        > div {
            margin-bottom: 0px;
            margin-right: 2%;
            float: left;
            width: 36%;
        }

        > div:last-child {
            margin-right: 0px;
            width: 32%;
        }

        > div:first-child {
            width: 28%;
        }
    }
`;
