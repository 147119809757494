import { gql } from "@apollo/client";
import * as React from "react";
import AnimateHeight from "react-animate-height";
import { FormattedMessage } from "react-intl";
import { GQLDealerInfoOpeningTimeFragment } from "src/graphql.apollo.generated";

import * as sc from "./DealerInfoItem.sc";

export const DealerInfoOpeningTimeFragment = gql`
    fragment DealerInfoOpeningTime on OpeningTimes {
        title
        mon
        tue
        wed
        thu
        fri
        sat
        sun
    }
`;

export interface Props {
    openingTime: GQLDealerInfoOpeningTimeFragment;
}

export const DealerInfoItem: React.FunctionComponent<Props> = ({ openingTime }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <sc.TitleWrapper open={open} onClick={() => setOpen(!open)}>
                {openingTime.title && <sc.Title>{openingTime.title}</sc.Title>}
                <sc.OpenCloseIcon open={open} />
            </sc.TitleWrapper>
            <AnimateHeight height={open ? `auto` : 0} duration={500}>
                <sc.OpeningTimesContainer>
                    {openingTime.mon && (
                        <sc.OpeningTimeContainer>
                            <sc.OpeningDay>
                                <FormattedMessage id="leaseme.monday" defaultMessage="Montag" />
                            </sc.OpeningDay>
                            <sc.OpeningTime>
                                {openingTime.mon.map((time) => (
                                    <div key={`mon-${time}`}>{time}</div>
                                ))}
                            </sc.OpeningTime>
                        </sc.OpeningTimeContainer>
                    )}

                    {openingTime.tue && (
                        <sc.OpeningTimeContainer>
                            <sc.OpeningDay>
                                <FormattedMessage id="leaseme.tuesday" defaultMessage="Dienstag" />
                            </sc.OpeningDay>
                            <sc.OpeningTime>
                                {openingTime.tue.map((time) => (
                                    <div key={`tue-${time}`}>{time}</div>
                                ))}
                            </sc.OpeningTime>
                        </sc.OpeningTimeContainer>
                    )}

                    {openingTime.wed && (
                        <sc.OpeningTimeContainer>
                            <sc.OpeningDay>
                                <FormattedMessage id="leaseme.wednesday" defaultMessage="Mittwoch" />
                            </sc.OpeningDay>
                            <sc.OpeningTime>
                                {openingTime.wed.map((time) => (
                                    <div key={`wed-${time}`}>{time}</div>
                                ))}
                            </sc.OpeningTime>
                        </sc.OpeningTimeContainer>
                    )}

                    {openingTime.thu && (
                        <sc.OpeningTimeContainer>
                            <sc.OpeningDay>
                                <FormattedMessage id="leaseme.thursday" defaultMessage="Donnerstag" />
                            </sc.OpeningDay>
                            <sc.OpeningTime>
                                {openingTime.thu.map((time) => (
                                    <div key={`thu-${time}`}>{time}</div>
                                ))}
                            </sc.OpeningTime>
                        </sc.OpeningTimeContainer>
                    )}

                    {openingTime.fri && (
                        <sc.OpeningTimeContainer>
                            <sc.OpeningDay>
                                <FormattedMessage id="leaseme.friday" defaultMessage="Freitag" />
                            </sc.OpeningDay>
                            <sc.OpeningTime>
                                {openingTime.fri.map((time) => (
                                    <div key={`fri-${time}`}>{time}</div>
                                ))}
                            </sc.OpeningTime>
                        </sc.OpeningTimeContainer>
                    )}

                    {openingTime.sat && (
                        <sc.OpeningTimeContainer>
                            <sc.OpeningDay>
                                <FormattedMessage id="leaseme.saturday" defaultMessage="Samstag" />
                            </sc.OpeningDay>
                            <sc.OpeningTime>
                                {openingTime.sat.map((time) => (
                                    <div key={`sat-${time}`}>{time}</div>
                                ))}
                            </sc.OpeningTime>
                        </sc.OpeningTimeContainer>
                    )}

                    {openingTime.sun && (
                        <sc.OpeningTimeContainer>
                            <sc.OpeningDay>
                                <FormattedMessage id="leaseme.sunday" defaultMessage="Sonntag" />
                            </sc.OpeningDay>
                            <sc.OpeningTime>
                                {openingTime.sun.map((time) => (
                                    <div key={`sun-${time}`}>{time}</div>
                                ))}
                            </sc.OpeningTime>
                        </sc.OpeningTimeContainer>
                    )}
                </sc.OpeningTimesContainer>
            </AnimateHeight>
        </div>
    );
};
