import { DefaultReactSelectStyle } from "@src/shared/searchResults/searchfilter/filter/ReactSelectStyle";
import { IResponseGroupParameterValues } from "@src/shared/utils/httpClient";
import * as React from "react";
import { Field } from "react-final-form";
import ReactSelect from "react-select";

import { CheckboxInput, CheckboxLabel } from "./Input.sc";

export interface ISelectProps {
    options: IResponseGroupParameterValues[];
    name: string;
    label: string;
    style?: string;
}

export interface ISelectState {
    initialValue?: string;
}

const Select: React.FunctionComponent<ISelectProps & ISelectState> = ({ name, style, label, options }) => {
    const [state, setState] = React.useState<ISelectState>({});
    const { initialValue } = state;
    const identifier = getIdentifier();

    React.useEffect(() => {
        for (const key in options) {
            if (options[key].default) {
                const value = options[key].valueFromAttribute;
                const initialValue = value === "true" ? true : value === "false" ? false : value;
                setState({ initialValue });
            }
        }
    }, [options]);

    function getIdentifier() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    const getSelectOptions = () =>
        options.map((option: IResponseGroupParameterValues) => ({
            value: option.valueFromAttribute,
            label: name === "durationOfEmploymentInYears" ? option.displayValue : option.value,
        }));

    return (
        <>
            {!style && initialValue && (
                <Field
                    name={name}
                    initialValue={initialValue}
                    render={({ input }) => (
                        <ReactSelect
                            {...input}
                            onChange={(option: { value: any; label: string }) => input.onChange(option ? option.value : "")}
                            options={getSelectOptions()}
                            placeholder={"Bitte wählen..."}
                            isSearchable={true}
                            classNamePrefix={"react-select"}
                            value={getSelectOptions().filter((option) => option.value === input.value)}
                            styles={DefaultReactSelectStyle}
                        />
                    )}
                />
            )}
            {style === "List" && typeof initialValue !== "undefined" && (
                <div key={initialValue}>
                    <Field id={name} name={name} type="checkbox" initialValue={initialValue}>
                        {({ input }) => (
                            <CheckboxInput {...input} id={`${identifier}-${name}`} onChange={(event: React.ChangeEvent) => input.onChange(event)} />
                        )}
                    </Field>
                    <CheckboxLabel htmlFor={`${identifier}-${name}`}>{label}</CheckboxLabel>
                </div>
            )}
        </>
    );
};

export default Select;
