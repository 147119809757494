import styled from "styled-components";

import { IComponentProps } from "./Section";

export const Wrapper = styled.div<IComponentProps>`
    padding: ${({ data }) => data.name === "Zusammenfassung" && "10px 15px"};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: ${({ data }) => (data.name === "Zusammenfassung" ? "10px 25px" : "30px 0 0 0")};
        margin: ${({ data }) => data.name === "Zusammenfassung" && "0 -25px 15px -25px"};
    }
`;

export const Headline = styled.h2`
    font-weight: 600;
    font-size: 31px;
`;

export const Disclaimer = styled.div`
    font-size: 16px;

    a {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        padding-left: 5px;
        transition: all 0.25s ease-in-out;
    }

    a:hover {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;
