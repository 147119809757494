import Images from "@src/shared/assets/images/images";
import styled, { css } from "styled-components";

export const Error = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 7px 0;
    line-height: 16px;
    font-size: 12px;
    display: none;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        display: block;
    }
`;

export const AbstractCheckmarkLabel = styled.label`
    display: block;
    position: relative;
    padding-left: 40px;
    line-height: 22px;
    cursor: pointer;

    &:before {
        content: "";
        box-sizing: border-box;
        height: 22px;
        width: 22px;
        border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
        position: absolute;
        top: -1px;
        left: 0;
        background-size: 11px 11px;
        background-repeat: no-repeat;
        background-position: 5px 5px;
        background-color: white;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding-right: 30px;
    }
`;

export const RadioLabel = styled(AbstractCheckmarkLabel)`
    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        min-width: 130px;
    }

    &:before {
        border-radius: 11px;
    }
`;

export const RadioInput = styled.input`
    display: none;

    &:checked + ${RadioLabel}:before {
        background-image: url(${Images.CheckmarkGrey});
    }
`;

export const CheckboxLabel = styled(AbstractCheckmarkLabel)`
    &:before {
        border-radius: 3px;
    }
`;

export const CheckboxInput = styled.input`
    display: none;

    ${CheckboxLabel}:before {
        border: 1px solid ${({ theme }) => theme.colors.gray5};
    }

    &:checked + ${CheckboxLabel}:before {
        background-image: url(${Images.CheckmarkWhite});
        background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;

interface IRootProps {
    startAdornment?: string;
    endAddornment?: HTMLElement;
}

export const Root = styled.div<IRootProps>`
    position: relative;
    height: 50px;

    font-size: 16px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ startAdornment }) =>
        startAdornment &&
        css`
            &::before {
                content: "${startAdornment}";
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                text-align: center;
                line-height: 50px;
                padding-left: 5px;
                color: ${({ theme }) => theme.colors.porscheBankBlue};
            }
        `}

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
`;

interface IInputProps {
    hasStartAdornment?: boolean;
    hasEndAdornment?: boolean;
    variant?: "default" | "dark";
}

export const Input = styled.input<IInputProps>`
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    border-radius: 1px;
    padding-left: ${({ hasStartAdornment }) => (hasStartAdornment ? 28 : 8)}px;
    padding-right: 8px;

    font-size: 16px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
`;
