import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import Images from "@src/shared/assets/images/images";
import { VehicleImage } from "@src/shared/home/latestOffers/VehicleCard.sc";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createGlobalStyle } from "styled-components";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";

import { CloseFullScreenButton, FullScreenButton, ImageWrapper, Index, OverlayFullScreen, SwiperRoot, SwiperSlider } from "./ImageSwiper.sc";

SwiperCore.use([Navigation, Pagination]);

export interface ImageSwiperProps {
    fullWidth?: boolean;
    images?: GQLVehicleDetailQuery["vehicle"]["images"];
}

const BodyStyle = createGlobalStyle<{ isSwiperFullscreen: boolean }>`
    && body {
        ${({ isSwiperFullscreen }) => isSwiperFullscreen && "overflow: hidden"}
    }
`;

export const ImageSwiper: React.FunctionComponent<ImageSwiperProps> = ({ images }) => {
    const [fullScreen, setFullScreen] = React.useState<boolean>(false);
    const [activeItem, setActiveItem] = React.useState(0);
    const intl = useIntl();
    if (images) {
        return (
            <>
                <BodyStyle isSwiperFullscreen={fullScreen} />
                <SwiperSlider
                    autoHeight={true}
                    autoplay={false}
                    loop={true}
                    shortSwipes={false}
                    longSwipesMs={100}
                    longSwipesRatio={0.1}
                    allowTouchMove={true}
                    slidesPerView={1}
                    centeredSlides={true}
                    navigation={true}
                    pagination={true}
                    fullWidth={fullScreen}
                    onSlideChange={(swiper) => setActiveItem(swiper.realIndex)}
                >
                    {images.map((image) => {
                        return (
                            <SwiperSlide key={image.uri!}>
                                <SwiperRoot isFullscreen={fullScreen}>
                                    <ImageWrapper isFullscreen={fullScreen}>
                                        <VehicleImage
                                            defaultImage={false}
                                            alt={intl.formatMessage({
                                                id: "leaseme.relatedLinks.card.image",
                                                defaultMessage: "Fahrzeug Vorschaubild",
                                            })}
                                            src={image.uri ?? Images.CarAvatar}
                                        />
                                    </ImageWrapper>
                                </SwiperRoot>
                            </SwiperSlide>
                        );
                    })}
                    <Index isFullscreen={fullScreen}>
                        <FormattedMessage id="leaseme.image" defaultMessage="Bild" /> {activeItem + 1} / {images.length}
                    </Index>
                    {fullScreen ? (
                        <CloseFullScreenButton isFullscreen={fullScreen} onClick={() => setFullScreen(false)} />
                    ) : (
                        <FullScreenButton isFullscreen={fullScreen} onClick={() => setFullScreen(true)} />
                    )}
                </SwiperSlider>
                <OverlayFullScreen fullWidth={fullScreen} />
            </>
        );
    } else {
        return (
            <ImageWrapper isFullscreen={fullScreen}>
                <VehicleImage
                    defaultImage={true}
                    alt={intl.formatMessage({
                        id: "leaseme.relatedLinks.card.image",
                        defaultMessage: "Fahrzeug Vorschaubild",
                    })}
                    src={Images.CarAvatar}
                />
            </ImageWrapper>
        );
    }
};
