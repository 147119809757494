import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const headerStyles = StyleSheet.create({
    headerContent: {
        position: "absolute",
        zIndex: 1,
        backgroundColor: theme.colors.porscheBankBlue,
        padding: "15pt 30pt",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        minHeight: "45pt",
    },
    textContainer: {
        flexDirection: "column",
    },
    textSubHeadline: {
        fontSize: "9pt",
        color: theme.colors.white,
        fontFamily: "MarkW1G",
    },
    headlineLogo: {
        height: "8mm",
        maxWidth: "30mm",
        marginBottom: "5mm",
    },
});
