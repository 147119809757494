import Images from "@src/shared/assets/images/images";
import styled from "styled-components";

import { IRowProps } from "./Row";

export const FormRowWrapper = styled.div<IRowProps>`
    display: ${({ parameter }) => {
        const type = parameter.control ? parameter.control.type : parameter.type;
        return type === "Hidden" ? "none" : "flex";
    }};

    position: relative;
    min-height: 40px;
    font-size: 16px;
    font-weight: ${({ parameter }) => {
        const emphasize = parameter.control ? parameter.control.emphasize : false;
        return emphasize ? 600 : 400;
    }};

    flex-flow: ${({ parameter }) => (parameter.control.dataType === "Select" && parameter.control.style === "List" ? "row wrap" : "nowrap")};
    flex-direction: column;
    margin-bottom: 20px;
`;
export const InputWrapper = styled.div<IRowProps>`
    align-items: center;
    position: relative;
    line-height: 24px;
    display: flex;
    font-weight: 400;
    width: 100%;
    margin-bottom: 10px;
`;
export const Unit = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-end;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        display: block;
        width: 15%;
    }
`;
export const Value = styled.div`
    box-sizing: border-box;
    text-align: right;
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding-left: 0px;
        text-align: left;
        max-width: 450px;
        min-width: 350px;
    }

    input[type="number"],
    input[type="text"],
    input[type="email"] {
        box-sizing: border-box;
        border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
        padding: 1px 40px 0 25px;
        border-radius: 1px;
        line-height: 22px;
        appearance: none;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        display: block;

        &:focus {
            outline-offset: 0;
            outline: none;
            background-color: ${({ theme }) => theme.colors.backgroundLightGray};
            border: 1px solid rgba(56, 58, 59, 0.5);
        }
    }
`;

export const ToolTip = styled.div`
    flex-basis: 100%;
    width: 100%;
    z-index: 2;
    padding: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
    }
`;

export const InnerToolTip = styled.div`
    margin-left: auto;
    position: absolute;
    margin-top: -10px;
    right: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    background: url(${Images.InfoIcon}) no-repeat center;
    background-size: cover;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-top: -8px;
        width: 15px;
        height: 15px;
    }

    .kwfLocal__toolTipText {
        a {
            color: ${({ theme }) => theme.colors.porscheBankBlue};
            transition: all 0.25s ease-in-out;
        }

        a:hover {
            font-weight: 700;
        }
    }

    &:hover .kwfLocal__toolTipText {
        display: block;
    }
`;

export const ToolTipText = styled.div`
    display: none;
    width: calc(100vw - 32px);
    box-sizing: border-box;
    font-size: 16px;
    position: absolute;
    z-index: 10;
    padding: 15px 25px;
    text-align: center;
    max-width: 342px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    background: ${({ theme }) => theme.colors.backgroundLightGray};
    bottom: 20px;
    right: 0;

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        text-align: left;
        max-width: 550px;
        width: 550px;
        bottom: 20px;
        left: -225px;
    }
`;

export const Error = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankRed};
    line-height: 14px;
    font-size: 10px;
    padding: 7px 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: none;
    }
`;

export const IconWrapper = styled.div`
    width: 55px;
    height: 55px;
    margin-top: -30px;
    padding: 20px;
    position: absolute;
    right: 0px;
    top: 50%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-top: -26px;
    }
`;
