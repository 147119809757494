import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const vehicleDataStyles = StyleSheet.create({
    root: {
        flexDirection: "column",
        paddingTop: "1cm",
    },
    horizontalLine: {
        width: "80pt",
        height: "3pt",
        backgroundColor: theme.colors.darkRed,
    },
    title: {
        fontSize: "9pt",
        fontWeight: "bold",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
        paddingBottom: "12pt",
        paddingTop: "8pt",
        textTransform: "uppercase",
    },
    emissionLabel: {
        fontSize: "8pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
    },
    emissionSubLabel: {
        fontSize: "4pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
        alignSelf: "flex-end",
    },
    content: {
        flexDirection: "column",
    },
});
