import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import * as sc from "./FinanceTypeSwitch.sc";

interface FinanceTypeSwitchProps {
    setFinanceType: (type: GQLFinanceType) => void;
    financeType: GQLFinanceType;
    leasingCapable: boolean;
}

const FinanceTypeSwitch: React.FunctionComponent<FinanceTypeSwitchProps> = ({ setFinanceType, financeType, leasingCapable }) => {
    const scope = getConfig("scope");
    return (
        <sc.Tabs>
            {scope.domain !== "car4me.porschefinance.hu" ? (
                <>
                    {leasingCapable && (
                        <sc.Tab onClick={() => setFinanceType(GQLFinanceType.leasing)} active={financeType === GQLFinanceType.leasing}>
                            <FormattedMessage id="leaseme.leasing" defaultMessage="Leasing" />
                        </sc.Tab>
                    )}
                    {getConfig("hasCredit") && (
                        <sc.Tab onClick={() => setFinanceType(GQLFinanceType.credit)} active={financeType === GQLFinanceType.credit}>
                            <FormattedMessage id="leaseme.credit" defaultMessage="Kredit" />
                        </sc.Tab>
                    )}
                </>
            ) : (
                <>
                    <sc.Tab onClick={() => setFinanceType(GQLFinanceType.openEndLeasing)} active={financeType === GQLFinanceType.openEndLeasing}>
                        <FormattedMessage id="leaseme.openEndLeasing" defaultMessage="Open-End-Leasing" />
                    </sc.Tab>
                    <sc.Tab onClick={() => setFinanceType(GQLFinanceType.closedEndLeasing)} active={financeType === GQLFinanceType.closedEndLeasing}>
                        <FormattedMessage id="leaseme.closedEndLeasing" defaultMessage="Closed-End-Leasing" />
                    </sc.Tab>
                </>
            )}
        </sc.Tabs>
    );
};

export default FinanceTypeSwitch;
