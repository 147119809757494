import { useQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import DesktopContainerAT from "@src/AT/vehicleDetail/desktop/DesktopContainer";
import DisclaimerAT from "@src/AT/vehicleDetail/disclaimer/Disclaimer";
import MobileDropdownAT from "@src/AT/vehicleDetail/mobile/MobileDropdown";
import ShareMenuItemsAT from "@src/AT/vehicleDetail/shareMenu/ShareMenuItems";
import CarDataContentBG from "@src/BG/vehicleDetail/carDataContent/CarDataContent";
import DisclaimerBG from "@src/BG/vehicleDetail/disclaimer/Disclaimer";
import ShareMenuItemsBG from "@src/BG/vehicleDetail/shareMenu/ShareMenuItems";
import { getConfig } from "@src/config";
import { GQLFinanceType, GQLVehicleDetailQuery, GQLVehicleDetailQueryVariables } from "@src/graphql.apollo.generated";
import DesktopContainerHU from "@src/HU/vehicleDetail/desktop/DesktopContainer";
import DisclaimerHU from "@src/HU/vehicleDetail/disclaimer/Disclaimer";
import MobileDropdownHU from "@src/HU/vehicleDetail/mobile/MobileDropdown";
import ShareMenuItemsHU from "@src/HU/vehicleDetail/shareMenu/ShareMenuItems";
import DesktopContainerRO from "@src/RO/vehicleDetail/desktop/DesktopContainer";
import DisclaimerRO from "@src/RO/vehicleDetail/disclaimer/Disclaimer";
import MobileDropdownRO from "@src/RO/vehicleDetail/mobile/MobileDropdown";
import ShareMenuItemsRO from "@src/RO/vehicleDetail/shareMenu/ShareMenuItems";
import FinCalcProvider from "@src/shared/context/fincalc/FinCalcProvider";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import buildOfferViewEvent from "@src/shared/tracking/buildOfferViewEvent";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import DesktopContainer from "@src/shared/vehicleDetail/desktop/DesktopContainer";
import { generateVehicleDetailUrl } from "@src/shared/vehicleDetail/generateVehicleDetailUrl";
import Header from "@src/shared/vehicleDetail/header/Header";
import MobileDropdown from "@src/shared/vehicleDetail/mobile/MobileDropdown";
import Title from "@src/shared/vehicleDetail/title/Title";
import DesktopContainerSI from "@src/SI/vehicleDetail/desktop/DesktopContainer";
import MobileDropdownSI from "@src/SI/vehicleDetail/mobile/MobileDropdown";
import ShareMenuItemsSI from "@src/SI/vehicleDetail/shareMenu/ShareMenuItems";
import Master from "@src/skeleton/layout/Master";
import { navigate } from "gatsby";
import * as React from "react";

import ImageContentSection from "./imageContentSection/ImageContentSection";
import { VehicleDetailQuery } from "./VehicleDetail.gql";
import * as sc from "./VehicleDetail.sc";

interface Props {
    vehicleId: string;
    mainMenu?: any;
}

const trackingEvent = getConfig("scope").domain === "lease-me.porschebank.at" ? "leaseme-offer-close" : "car4me-offer-close";

const disclaimerAsterisk = getConfig("scope").domain === "lease-me.porschebank.at" ? " *" : undefined;

function getShareMenuItems() {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return <ShareMenuItemsBG />;
        case "car4me.ro":
            return <ShareMenuItemsRO />;
        case "car4me.si":
            return <ShareMenuItemsSI />;
        case "car4me.porschefinance.hu":
            return <ShareMenuItemsHU />;
        default:
            return <ShareMenuItemsAT />;
    }
}

const Content: React.FC<{ vehicle: GQLVehicleDetailQuery["vehicle"] }> = ({ vehicle }) => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return (
                <>
                    <MobileDropdown vehicle={vehicle} carDataContent={<CarDataContentBG vehicle={vehicle} />} />
                    <DesktopContainer vehicle={vehicle} carDataContent={<CarDataContentBG vehicle={vehicle} />} />
                    <DisclaimerBG />
                </>
            );
        case "car4me.ro":
            return (
                <>
                    <MobileDropdownRO vehicle={vehicle} />
                    <DesktopContainerRO vehicle={vehicle} />
                    <DisclaimerRO />
                </>
            );
        case "car4me.si":
            return (
                <>
                    <MobileDropdownSI vehicle={vehicle} />
                    <DesktopContainerSI vehicle={vehicle} />
                </>
            );
        case "car4me.porschefinance.hu":
            return (
                <>
                    <MobileDropdownHU vehicle={vehicle} />
                    <DesktopContainerHU vehicle={vehicle} />
                    <DisclaimerHU />
                </>
            );
        default:
            return (
                <>
                    <MobileDropdownAT vehicle={vehicle} />
                    <DesktopContainerAT vehicle={vehicle} />
                    <DisclaimerAT />
                </>
            );
    }
};

const VehicleDetail = ({ vehicleId }: Props) => {
    const location = useLocation();
    const { filter, setFilter } = useSearchFilter();

    const { data, loading, error } = useQuery<GQLVehicleDetailQuery, GQLVehicleDetailQueryVariables>(VehicleDetailQuery, {
        variables: {
            vehicleId,
            financeType: filter.financeType,
            financeRuntime: filter.financeRuntime,
            downPayment: filter.downPayment,
            yearlyMileage: filter.kmPerYear,
            leasemeScope: getConfig("scope"),
            residualCreditInPercent: filter.remainingCredit,
        },
    });

    const [vehicle, setVehicle] = React.useState<any>(null);

    React.useEffect(() => {
        if (data?.vehicle) {
            setVehicle(data.vehicle);

            //TODO: Recheck for HU
            const leasingCapable = data.vehicle.leasingRate !== null;
            if (!leasingCapable && filter.financeType === GQLFinanceType.leasing && setFilter) setFilter("financeType", GQLFinanceType.credit, false);

            if (data.vehicle.finCalcData?.downPayment && data.vehicle.finCalcData.downPayment < filter.downPayment)
                setFilter?.("downPayment", data.vehicle.finCalcData.downPayment, false);
        }
    }, [data, setFilter, filter.financeType, filter.downPayment]);

    // Ref to prevent duplicate GTM Events
    const filterRef = React.useRef(filter);

    React.useEffect(() => {
        filterRef.current = filter;
    }, [filter]);

    React.useEffect(() => {
        window.history.replaceState({}, "", generateVehicleDetailUrl({ vehicleId }, filter, location));
    }, [filter, vehicleId, location]);

    React.useEffect(() => {
        if (!vehicle) {
            return;
        }

        pushToDataLayer(buildOfferViewEvent(vehicle, filterRef.current));
    }, [vehicle]);

    if (error) {
        if (typeof window === "object") {
            navigate("/404");
        }

        return null;
    }

    if (!vehicle) {
        return (
            <Master marginTop={80}>
                {loading && (
                    <sc.Loading>
                        <sc.LoadingIcon />
                    </sc.Loading>
                )}
            </Master>
        );
    }

    return (
        <FinCalcProvider>
            <Master marginTop={80}>
                <sc.Root>
                    <Header trackingEvent={trackingEvent} vehicle={vehicle} />
                    <Title vehicle={vehicle} shareMenuItems={getShareMenuItems()} disclaimerAsterisk={disclaimerAsterisk} />
                    <ImageContentSection vehicle={vehicle} shareMenuItems={getShareMenuItems()} />
                    <Content vehicle={vehicle} />
                </sc.Root>
            </Master>
        </FinCalcProvider>
    );
};
export default VehicleDetail;
