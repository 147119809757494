import { Text } from "@react-pdf/renderer";
import * as React from "react";

import { footerStyles } from "./FooterStyles";

interface IFooterProps {}

const FooterComponent: React.FunctionComponent<IFooterProps> = () => {
    return (
        <Text
            style={footerStyles.pageNumber}
            render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }) => `${pageNumber} / ${totalPages}`}
            fixed
        />
    );
};

export default FooterComponent;
