import Button, { ButtonSize, ButtonVariant } from "@src/shared/button/Button";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface CompletionButtonProps {
    onClick: () => void;
}

export const OnlineClosureButton = ({ onClick }: CompletionButtonProps) => {
    return (
        <OnlineClosureButtonWrapper>
            <Button size={ButtonSize.Default} variant={ButtonVariant.Primary} onClick={onClick} data-gtm-event="online-closure-form-link">
                <OnlineClosureButtonText>
                    <FormattedMessage id="leaseme.completion.request" defaultMessage="Online-Abschluss anfragen" />
                </OnlineClosureButtonText>
            </Button>
        </OnlineClosureButtonWrapper>
    );
};

const OnlineClosureButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 20px 0;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        margin: 0 0 30px 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
`;

const OnlineClosureButtonText = styled.div`
    text-transform: uppercase;
`;
