import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Disclaimer: React.FC = () => {
    return (
        <DisclaimerRoot>
            {"* "}
            <FormattedMessage
                id="leaseme.vehicleData.disclaimer"
                defaultMessage="Abbildungen können Symbolfotos sein. Der tatsächliche
                                        km-Stand kann sich bis zur Abholung noch erhöhen.
                                        EU-Information über Kraftstoffverbrauch und
                                        CO2-Emissionen gemäß VO (EG) 715/2007: Die angegebenen
                                        Werte wurden nach den vorgeschriebenen Messverfahren VO
                                        (EG) 715/2007 ermittelt. Die Angaben beziehen sich nicht
                                        auf ein einzelnes Fahrzeug und sind nicht Bestandteil
                                        des Angebotes, sondern dienen allein Vergleichszwecken
                                        zwischen den verschiedenen Fahrzeugtypen. Die Werte des
                                        kaufgegenständlichen Fahrzeuges können daher nach unten
                                        bzw. oben vom Angebot abweichen. Insbesondere können
                                        sich durch Sonderausstattungen und Zubehör (z.B.
                                        breitere Reifen, Klimaanlage, Dachgepäcksträger etc.)
                                        abweichende Verbrauchswerte und CO2-Emissionen ergeben.
                                        Dies kann einen höheren NoVA-Satz bedeuten. Zwischenzeitlicher Verkauf vorweggenommen.
                                        Angaben über ein Fahrzeug stammen nicht von LeaseMe und
                                        können in Einzelfällen unvollständig bzw. fehlerhaft
                                        sein, weshalb LeaseMe bei Interesse eine vorherige
                                        Kontaktaufnahme mit dem Verkäufer empfiehlt. Änderungen
                                        in Modellvarianten, Konstruktion, Ausstattung,
                                        technische Daten, Preis sowie Eingabefehler sind
                                        ausdrücklich vorbehalten. Die abgebildeten Fahrzeuge
                                        sind teilweise mit Mehrausstattung gegen Mehrpreis
                                        ausgerüstet bzw. zeigen die Abbildungen teilweise
                                        aufpreispflichtige Mehrausstattungen und
                                        Ausstattungsdetails, die nicht für alle Modellvarianten
                                        verfügbar sind. Bitte erkundigen Sie sich vor Abschluss
                                        das Kaufvertrages über den genauen Ausstattungs- bzw.
                                        Serienumfang sowie den Preis Ihres Fahrzeuges. Für die
                                        von den Verkäufern eingestellten Angebote übernimmt
                                        LeaseMe keine Haftung. Auf das Angebot besteht kein
                                        Rechtsanspruch."
            />
        </DisclaimerRoot>
    );
};

export default Disclaimer;

const DisclaimerRoot = styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 0 20px;
    margin-bottom: 70px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
        font-size: 16px;
        line-height: 24px;
    }
`;
