import styled from "styled-components";

export const Root = styled.div`
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};
    margin-bottom: 10px;
    gap: 20px;
`;

export const Value = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
    display: flex;
    align-items: center;
`;

export const Label = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
`;
