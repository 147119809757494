import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { IntlShape } from "react-intl/src/types";

import { IFormValues } from "./ContactForm";

const domain = getConfig("scope").domain;
const contactForm = JSON.parse(getConfig("contactForm"))[domain];

export function getContactMessage(values: IFormValues, vehicle: GQLVehicleDetailQuery["vehicle"], intl: IntlShape) {
    let contactMessage = values.message;

    if (contactForm.hasCheckboxData || contactForm.hasResidenceAndContactHours)
        contactMessage += `\n\n${intl.formatMessage({
            id: "leaseme.contactForm.comment",
            defaultMessage: "Anmerkung",
        })}:`;

    if (contactForm.hasCheckboxData) {
        contactMessage += `\n\t${intl.formatMessage({
            id: "leaseme.contactForm.changeCar.label",
            defaultMessage: "Ich würde gerne mein Auto wechseln",
        })}: ${
            values.changeCar
                ? intl.formatMessage({
                      id: "leaseme.yes",
                      defaultMessage: "Ja",
                  })
                : intl.formatMessage({
                      id: "leaseme.no",
                      defaultMessage: "Nein",
                  })
        }`;

        contactMessage += `\n\t${intl.formatMessage({
            id: "leaseme.contactForm.financeOffer.label",
            defaultMessage: "Ich würde gerne ein Finanzierungsangebot zugeschickt bekommen",
        })}: ${
            values.financeOffer
                ? intl.formatMessage({
                      id: "leaseme.yes",
                      defaultMessage: "Ja",
                  })
                : intl.formatMessage({
                      id: "leaseme.no",
                      defaultMessage: "Nein",
                  })
        }`;
    }

    return (
        `${intl.formatMessage({
            id: "leaseme.contactManager.headline",
            defaultMessage: "Der Kunde hat über www.lease-me.at eine Anfrage gestellt:",
        })}\n\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.customer",
            defaultMessage: "Kunde:",
        })}\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.name",
            defaultMessage: "Name:",
        })} ${values.firstname} ${values.lastname} \n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.email",
            defaultMessage: "E-Mail:",
        })} ${values.email}\n\n` +
        `${contactMessage}\n\n` +
        `${vehicle.model}\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.bnr",
            defaultMessage: "Bestandsnr.:",
        })} ${vehicle.offerReference}\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.dwa",
            defaultMessage: "Weltauto ID:",
        })} ${vehicle.vehicleId}\n` +
        `${intl.formatMessage({ id: "leaseme.contactManager.linkLabel", defaultMessage: "Details entnehmen Sie bitte hier:" })}`
        /**
         ${intl.formatMessage({
            id: "leaseme.contactManager.details",
            defaultMessage: "Details entnehmen Sie bitte dem angehängten PDF.",
        })}
         */
    );
}

export function getCopyMailText(values: IFormValues, vehicle: GQLVehicleDetailQuery["vehicle"], intl: IntlShape) {
    return (
        `${intl.formatMessage({
            id: "leaseme.contactManager.copyMail.headline",
            defaultMessage: "Folgende Anfrage wurde soeben erstellt:",
        })}\n\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.copyMail.firstname",
            defaultMessage: "Vorname:",
        })} ${values.firstname}\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.copyMail.lastname",
            defaultMessage: "Familienname:",
        })} ${values.lastname}\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.email",
            defaultMessage: "E-Mail:",
        })} ${values.email}\n\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.copyMail.dealer",
            defaultMessage: "Ausgewählter Betrieb:",
        })} ${vehicle.dealer?.bnr}\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.copyMail.message",
            defaultMessage: "Nachricht:",
        })} ${values.message}\n\n` +
        `${intl.formatMessage({
            id: "leaseme.contactManager.copyMail.contactManager.text",
            defaultMessage: "Text an Contact-Manager:",
        })}\n\n${getContactMessage(values, vehicle, intl)}\n`
    );
}
