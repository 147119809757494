import { StyleSheet } from "@react-pdf/renderer";
import theme from "@src/shared/theme/theme-porschebank";

export const footnoteStyles = StyleSheet.create({
    root: {
        margin: "15pt 0",
    },
    footerText: {
        fontSize: "6pt",
        fontFamily: "MarkW1G",
        color: theme.colors.porscheBankBlue,
    },
});
