import { gql } from "@apollo/client";

import { DealerInfoOpeningTimeFragment } from "./DealerInfoItem";

export const VehicleDetailDealerInfo = gql`
    fragment VehicleDetailDealerInfo on Dealer {
        stageimgUrl
        name
        street
        city
        zip
        telephone
        longitude
        latitude
        openingTimes {
            ...DealerInfoOpeningTime
        }
        bnr
    }

    ${DealerInfoOpeningTimeFragment}
`;
