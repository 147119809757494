import { Maybe } from "@src/graphql.apollo.generated";
import styled, { css } from "styled-components";

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
`;

interface ColumnProps {
    largeText?: Maybe<boolean>;
    bold?: Maybe<boolean>;
    isFullWidth?: boolean;
    hasError?: boolean;
    isValueColumn?: boolean;
}

export const Column = styled.div<ColumnProps>`
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme, hasError }) => (hasError ? theme.colors.darkRed : theme.colors.porscheBankBlue)};
    width: ${({ isFullWidth = false }) => (isFullWidth ? "100%" : "auto")};
    display: flex;
    align-items: center;
    position: relative;
    justify-content: ${({ isValueColumn }) => (isValueColumn ? "flex-end" : "flex-start")};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding-right: 30px;
        font-size: 16px;
        ${({ largeText }) =>
            largeText &&
            `
            font-size: 18px;
        `}
    }

    ${({ largeText }) =>
        largeText &&
        `
        font-size: 16px;
        line-height: 30px;
    `}

    ${({ bold }) =>
        bold &&
        `
        font-weight: 700;
    `}
`;

export const InfoIconWrapper = styled.div`
    position: absolute;
    right: -35px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        right: 0;
    }
`;

interface UnitProps {
    hasError?: boolean;
}

export const Unit = styled.div<UnitProps>`
    margin-right: 10px;

    ${({ hasError, theme }) =>
        hasError &&
        css`
            color: ${theme.colors.darkRed};
        `}
`;

interface SelectContainerWrapperProps {
    flexStyle?: "row" | "column";
    selectStyle?: "radio";
}

export const SelectContainerWrapper = styled.div<SelectContainerWrapperProps>`
    display: flex;
    flex-direction: ${({ flexStyle = "column" }) => (flexStyle === "column" ? "column" : "row")};
    justify-content: space-between;
    max-width: 200px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 200px;
    }

    ${({ flexStyle = "column" }) =>
        flexStyle === "row" &&
        `
        gap: 15px;
    `}

    ${({ selectStyle }) =>
        selectStyle === "radio" &&
        css`
            width: 100%;
            min-width: 100%;
            justify-content: flex-start;
            padding-top: 10px;
        `}
`;

export const Error = styled.div`
    color: ${({ theme }) => theme.colors.darkRed};
    padding-top: 10px;
    font-size: 14px;

    a {
        color: ${({ theme }) => theme.colors.darkRed};
        font-weight: 700;
        text-decoration: none;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 16px;
    }
`;

export const Root = styled.div`
    padding: 10px 0;
    margin-right: 30px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-right: 0;
    }
`;
