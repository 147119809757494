import { gql } from "@apollo/client";

const boniCheckParameterFragment = gql`
    fragment BoniCheckParameter on BoniCheck {
        groups {
            name
            parameters {
                id
                label
                help
                value
                type
                units
                control {
                    type
                    dataType
                    style
                }
                data {
                    default
                    value
                    valueFromAttribute
                }
            }
        }
        control {
            checkPossible
        }
        title
        disclaimer
        info
        errors {
            field
            description
        }
    }
`;

export const boniCheckQuery = gql`
    query BoniCheck($id: String!, $leasemeScope: ContentScopeInput!) {
        FinCalcProducts: vehicle(id: $id, scope: $leasemeScope) {
            finCalcProductsLeasing: finCalcProducts(financeType: leasing) {
                rate
                cashBenefit
            }
            finCalcProductsCredit: finCalcProducts(financeType: credit) {
                rate
                cashBenefit
            }
        }
        BoniCheckParameter: getBoniCheckParameter {
            ...BoniCheckParameter
        }
    }
    ${boniCheckParameterFragment}
`;

export const boniCheckUpdateQuery = gql`
    query BoniCheckUpdate(
        $vehicleId: String!
        $financeData: FinanceDataInput!
        $vehicleSearchData: VehicleSearchData!
        $personalData: PersonalData!
        $leasemeScope: ContentScopeInput!
    ) {
        checkIfBoniIsAvailable(
            vehicleId: $vehicleId
            financeData: $financeData
            vehicleSearchData: $vehicleSearchData
            personalData: $personalData
            scope: $leasemeScope
        ) {
            ...BoniCheckParameter
        }
    }
    ${boniCheckParameterFragment}
`;
