import { getConfig } from "@src/config";
import { GQLVehicleDetailDealerInfoFragment } from "@src/graphql.apollo.generated";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./DealerInfo.sc";
import { DealerInfoItem } from "./DealerInfoItem";
import { DealerMap } from "./DealerMap";

interface IDealerInfoProps {
    dealer: GQLVehicleDetailDealerInfoFragment;
}

const DealerInfo: React.FunctionComponent<IDealerInfoProps> = ({ dealer }) => {
    const intl = useIntl();

    return (
        <sc.DealerInfo>
            <sc.PlaceholderDealer>
                <sc.DealerHead>
                    {getConfig("hasDealerMap") && dealer.latitude && dealer.longitude ? (
                        <sc.DealerMapWrapper>
                            <DealerMap lat={dealer.latitude} lng={dealer.longitude} />
                        </sc.DealerMapWrapper>
                    ) : (
                        dealer.stageimgUrl && (
                            <sc.DealerImage
                                src={dealer.stageimgUrl}
                                alt={intl.formatMessage({
                                    id: "leaseme.dealer.image",
                                    defaultMessage: "Händlerbild",
                                })}
                            />
                        )
                    )}

                    <sc.DealerAddress>
                        <sc.DealerAddressHeadline>{dealer.name ?? "-"}</sc.DealerAddressHeadline>
                        <sc.DealerAddressText>{dealer.street ?? "-"}</sc.DealerAddressText>
                        {dealer.zip && dealer.city && (
                            <sc.DealerAddressText>
                                {dealer.zip ?? ""} {dealer.city}
                            </sc.DealerAddressText>
                        )}
                        <sc.PhoneContainer>
                            <FormattedMessage id="leaseme.phone.label" defaultMessage="Tel.:" />{" "}
                            <sc.Phone href={dealer.telephone ?? `tel:${dealer.telephone}`}>{dealer.telephone ?? "-"}</sc.Phone>
                        </sc.PhoneContainer>
                    </sc.DealerAddress>
                </sc.DealerHead>
                <sc.DealerOpeningTimes>
                    <sc.OpeningTimesTitle>
                        <FormattedMessage id="leaseme.dealer.openingTimes" defaultMessage="Öffnungszeiten" />
                    </sc.OpeningTimesTitle>
                    {dealer.openingTimes?.map((openingTime) => {
                        if (openingTime.title) return <DealerInfoItem key={openingTime.title} openingTime={openingTime} />;
                    })}
                </sc.DealerOpeningTimes>
            </sc.PlaceholderDealer>
        </sc.DealerInfo>
    );
};

export default DealerInfo;
