import SharedHeader from "@src/shared/vehicleDetail/pdf/header/Header";
import * as React from "react";

import Logo_si from "../../../../../static/si/Porsche_Slovenia_Logo_4C_white.png";

const Header: React.FC = () => {
    return <SharedHeader logo={Logo_si} />;
};

export default Header;
