import * as ChevronDownRoot from "@src/shared/assets/images/chevron_down.inline.svg";
import AnimateHeight from "react-animate-height";
import styled, { css } from "styled-components";

interface RootProps {
    hasNoIcon?: boolean;
}

export const Root = styled.div<RootProps>`
    position: relative;
    width: 100%;
    background-color: white;
    cursor: ${({ hasNoIcon }) => (hasNoIcon ? "auto" : "pointer")};
`;

interface HeaderProps {
    disableMargin?: boolean;
    alignItems?: "center" | "baseline";
}

export const Header = styled.div<HeaderProps>`
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: ${({ alignItems }) => alignItems ?? "center"};
    ${({ disableMargin }) =>
        !disableMargin &&
        css`
            margin: 20px 20px 0;
        `};
    justify-content: space-between;
`;

export const Collapseable = styled(AnimateHeight)`
    background-color: white;
    overflow: hidden;
    position: relative;
`;

export const ChevronDown = styled(ChevronDownRoot)`
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    height: 15px;
    width: 15px;
    min-width: 15px;
    transform: rotate(0deg);

    ${({ open }) =>
        open &&
        css`
            transform: rotate(180deg);
        `}
`;
