import Images from "@src/shared/assets/images/images";
import * as CloseIcon from "@src/shared/assets/images/times.inline.svg";
import Button from "@src/shared/button/Button";
import { Field } from "react-final-form";
import styled from "styled-components";

import { AbstractCheckmarkLabel } from "../../../AT/vehicleDetail/boniCheck/FormFields/Input.sc";

export const ContactForm = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 10px;
    position: relative;
    margin: 20px;
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};

    scroll-margin-top: 80px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 40px 0;
        padding: 20px;

        scroll-margin-top: 120px;
    }
`;

export const ContactFormHeadline = styled.h2`
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    margin: 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        line-height: 30px;
    }
    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 42px;
    }
`;

export const LabelFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        max-width: calc(50% - 10px);
    }
`;

export const Label = styled.label`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
`;

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankRed};
    margin-left: 5px;
`;

export const CustomCloseIcon = styled(CloseIcon)`
    width: 20px;
    height: 20px;

    &:hover {
        cursor: pointer;
    }

    path {
        stroke-width: 64px;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 30px;
        height: 30px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-bottom: 40px;
    }
`;

export const MessageContainer = styled.div`
    flex-grow: 1;
    margin-top: 20px;
    max-width: 900px;
`;

export const PersonInfoContainer = styled.div`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        flex-direction: row;
        max-width: 900px;
    }
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledInputField = styled(Field)`
    height: 50px;
    padding: 10px;
    font-size: 14px;
    margin-top: 10px;

    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    border-radius: 1px;
`;

export const StyledInputFieldContainer = styled.div`
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
`;

export const StyledTextArea = styled(Field)`
    height: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    border-radius: 1px;
    box-sizing: border-box;
    resize: none;
    min-height: 200px;
    font-family: ${({ theme }) => theme.fonts.roboto};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        height: calc(100% - 33px);
    }
`;

export const MessageFieldLabelContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const CheckboxContainer = styled.div`
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const CheckboxInputContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

export const CheckboxLabel = styled(AbstractCheckmarkLabel)`
    border-radius: 3px;
    margin-left: 5px;
`;

export const CheckboxInput = styled.input`
    display: none;

    ${CheckboxLabel}:before {
        border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    }

    &:checked + ${CheckboxLabel}:before {
        background-image: url(${Images.CheckmarkWhite});
        background-color: ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;

export const StyledLink = styled.a`
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    &:visited {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        align-items: baseline;
    }
`;

export const Loading = styled.div`
    z-index: 1;
    content: "";
    background: none;
    display: block;
    position: relative;
    width: 120px;
    height: 18px;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 18px;
        width: 18px;
        animation: rotate 0.8s infinite linear, fadeInLoading 2s linear;
        border: 2px solid ${({ theme }) => theme.colors.typo};
        border-right-color: transparent;
        border-radius: 50%;
        opacity: 0.6;
        margin: 0 auto;
        bottom: -2px;
        right: 49px;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes fadeInLoading {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.6;
        }
    }
`;

export const RequiredInfoContainer = styled.p`
    margin: 0 0 20px 20px;
    display: flex;
    justify-content: flex-start;
`;

export const SendContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-bottom: 40px;
    }
`;

export const StyledButton = styled(Button)`
    max-width: 320px;
    margin-top: 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-top: 10px;
    }
`;
