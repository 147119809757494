import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import FinCalcLightbox from "@src/RO/finCalcLightbox/FinCalcLightbox";
import CarDataContent from "@src/shared/vehicleDetail/carDataContent/CarDataContent";
import SharedDesktopContainer from "@src/shared/vehicleDetail/desktop/DesktopContainer";
import React from "react";

interface DesktopContainerProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
}

const DesktopContainer: React.FC<DesktopContainerProps> = ({ vehicle }) => {
    return (
        <SharedDesktopContainer vehicle={vehicle} carDataContent={<CarDataContent vehicle={vehicle} />}>
            <FinCalcLightbox />
        </SharedDesktopContainer>
    );
};

export default DesktopContainer;
