import { getConfig } from "@src/config";
import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import React from "react";

import InfoIcon from "../../infoIcon/InfoIcon";
import { Loading } from "../FinCalcLightbox.sc";
import { Column, Error, InfoIconWrapper, Root, Row, Unit } from "../formStyles.sc";

interface LabelProps {
    parameter: GQLFinanceInformationParameter;
    bold?: boolean;
    loading?: boolean;
}

const Label: React.FC<LabelProps> = ({ parameter, bold, loading }) => {
    return (
        <Root key={parameter.id}>
            <Row>
                <Column bold={bold ?? parameter.bold} largeText={bold ?? (parameter.bold && parameter.dataType === "Decimal")}>
                    {parameter.label}{" "}
                </Column>
                <Column
                    bold={bold ?? parameter.bold}
                    largeText={bold ?? (parameter.bold && parameter.dataType === "Decimal")}
                    hasError={parameter.error != null}
                >
                    {loading && (parameter.id === "Rate" || parameter.id === "TotalRateSum") ? (
                        <Loading />
                    ) : (
                        <>
                            <Unit>{parameter.unit}</Unit>
                            <div>{parameter.value}</div>
                            {(parameter.id === "Rate" || parameter.id === "TotalRateSum") && getConfig("scope").domain !== "car4me.si" && (
                                <div>**</div>
                            )}
                        </>
                    )}
                    {parameter.help && (
                        <InfoIconWrapper>
                            <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help ?? "" }} />} isClickable />
                        </InfoIconWrapper>
                    )}
                </Column>
            </Row>
            <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />
        </Root>
    );
};

export default Label;
