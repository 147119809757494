import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Disclaimer: React.FC = () => {
    return (
        <DisclaimerRoot>
            {"* "}
            <FormattedMessage
                id="leaseme.vehicleData.disclaimer.bg"
                defaultMessage="Die monatliche Zahlung versteht sich ohne Mehrwertsteuer, die anderen Beträge verstehen sich inklusive 20% Mehrwertsteuer. Die Bilder können illustrativ sein. Der tatsächliche Kilometerstand kann sich bis zur Abholung des Fahrzeugs erhöhen. Wir behalten uns das Recht vor, das Fahrzeug in der Zwischenzeit zu verkaufen. Die Angaben zu einem Fahrzeug stammen nicht von Car4me und können im Einzelfall unvollständig oder falsch sein, daher empfehlen wir Ihnen, sich vorher mit einem Händler in Verbindung zu setzen. Änderungen der Modellvarianten, der Konstruktion, der Ausstattung, der technischen Daten, des Preises sowie Irrtümer bei der Eingabe sind vorbehalten. Car4me ist nicht verantwortlich für die von Händlern veröffentlichten Angebote."
            />
        </DisclaimerRoot>
    );
};

export default Disclaimer;

const DisclaimerRoot = styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 0 20px;
    margin-bottom: 70px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
        font-size: 16px;
        line-height: 24px;
    }
`;
