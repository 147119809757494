import { Image, View } from "@react-pdf/renderer";
import * as React from "react";

import Triangle from "../../../../../static/pdfTriangle.png";
import { headerStyles } from "./HeaderStyles";
interface HeaderProps {
    logo: any;
}

const Header: React.FC<HeaderProps> = ({ children, logo }) => {
    return (
        <View style={headerStyles.root} fixed>
            <Image src={Triangle} />
            {children}
            <View style={headerStyles.logoImage}>
                <Image src={logo} />
            </View>
        </View>
    );
};

export default Header;
