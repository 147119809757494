import { useTheme } from "styled-components";

import { useWindowSize } from "./useWindowSize";

export function useIsMobile() {
    const theme = useTheme();
    const windowSize = useWindowSize();

    return Boolean(windowSize?.width && windowSize.width <= theme.breakpoints.md.value);
}
