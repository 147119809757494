import { GQLFinanceInformation } from "@src/graphql.apollo.generated";
import * as React from "react";

export interface IFinCalcContext {
    financeInformation?: GQLFinanceInformation;
    setFinCalcInformation: (financeInformation?: GQLFinanceInformation) => void;
}

const FinCalcContext = React.createContext<IFinCalcContext>({
    financeInformation: undefined,
    setFinCalcInformation: () => {},
});
export { FinCalcContext };
