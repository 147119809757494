import axios from "axios";

const contactManagerURL = typeof window === "object" ? process.env.EXTERNAL_API_URL : `${process.env.API_URL}`;

export interface IResponseGroupParameterValues {
    value: string | boolean;
    [key: string]: any;
}

export interface IResponseGroupParameter {
    control: IResponseGroupParameterValues;
    data: IResponseGroupParameterValues[];
    default?: IResponseGroupParameterValues;
    note?: string;
    units: string;
    label: string;
    help?: string;
    [key: string]: any;
}

export interface IResponseGroup {
    label: string;
    disclaimer: string;
    parameters: IResponseGroupParameter[];
    [key: string]: any;
}

const httpClient = axios.create({
    timeout: 30000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
});

export const postContactDealerForm = (postData: object) => {
    return httpClient.post(`${contactManagerURL}/contact-manager/send-mail`, postData).then((response) => response);
};
