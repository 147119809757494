import { GQLFinanceInformation } from "@src/graphql.apollo.generated";
import * as React from "react";

import { FinCalcContext } from "./FinCalcContext";

const FinCalcProvider: React.FunctionComponent = ({ children }) => {
    const [financeInformation, setFinCalcInformation] = React.useState<GQLFinanceInformation | undefined>();
    return <FinCalcContext.Provider value={{ financeInformation, setFinCalcInformation }}>{children}</FinCalcContext.Provider>;
};

export default FinCalcProvider;
