import styled from "styled-components";

export const DealerInfo = styled.div`
    margin-top: 20px;
    padding: 0 35px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 100%;
        padding: 0;
    }
`;

export const PlaceholderDealer = styled.div`
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
    }
`;

export const DealerHead = styled.div`
    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: flex;
        gap: 30px;
    }
`;

export const DealerImage = styled.img`
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 33%;
        margin: 0;
    }
`;

export const DealerMapWrapper = styled.div`
    width: 100%;
    height: 250px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 50%;
    }
`;

export const DealerAddress = styled.div`
    width: 100%;
    margin-top: 10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0;
    }
`;

export const DealerAddressHeadline = styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 5px 0;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const DealerAddressText = styled(DealerAddressHeadline)`
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const OpeningTimesTitle = styled.p`
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    line-height: 34px;
    margin: 0;
`;

export const OpeningTimes = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;

    b {
        font-size: 16px;
    }
`;

export const DealerOpeningTimes = styled.div`
    margin: 0;
    padding: 20px 0;
    font-size: 16px;
    line-height: 22px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 40px 0 0;
        font-size: 18px;
        line-height: 30px;
    }
`;

export const PhoneContainer = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const Phone = styled.a`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
`;

export const OpeningTimesButton = styled.div`
    margin-top: 5px;
`;

export const OpeningTimesName = styled.div`
    font-size: 20px;
    line-height: 34px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 10px 20px;
`;
