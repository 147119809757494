import { GQLFinanceInformationQuery } from "@src/graphql.apollo.generated";
import React from "react";
import { Form, FormSpy } from "react-final-form";

import FinCalcLightboxGroup from "./FinCalcLightboxGroup";
import { Error } from "./formStyles.sc";

interface FinCalcLightboxFormProps {
    data: GQLFinanceInformationQuery["vehicle"]["financeInformation"];
    onSubmit: (values: any) => void;
    loading?: boolean;
}

const FinCalcLightboxForm: React.FC<FinCalcLightboxFormProps> = ({ data, onSubmit, loading }) => {
    const initialValues = React.useMemo(() => getInitialValues(data), [data]);

    return (
        <Form
            key="finCalcLightboxForm"
            onSubmit={onSubmit}
            render={({ form, submitError }) => {
                return (
                    <>
                        <FormSpy
                            key="formSpy"
                            onChange={({ dirty }) => {
                                if (dirty) {
                                    form.submit();
                                }
                            }}
                            subscription={{
                                dirty: true,
                            }}
                        />
                        {submitError &&
                            submitError.length > 0 &&
                            submitError.map((error: { description: string }, index: number) => (
                                <Error key={index} dangerouslySetInnerHTML={{ __html: error.description }} />
                            ))}
                        <form>
                            {data?.groups.map((group) => (
                                <FinCalcLightboxGroup group={group} key={group.label} loading={loading} />
                            ))}
                        </form>
                    </>
                );
            }}
            initialValues={initialValues}
        />
    );
};

function getInitialValues(data: GQLFinanceInformationQuery["vehicle"]["financeInformation"]) {
    const initialValues: Record<string, unknown> = {};

    if (data?.groups) {
        for (const group of data.groups) {
            for (const { id, value, defaultOption } of group.parameters) {
                if (id) initialValues[id] = value || defaultOption;
            }
        }
    }

    return initialValues;
}

export default FinCalcLightboxForm;
