import styled from "styled-components";

export const Root = styled.div`
    margin-top: 40px;
    padding: 0 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const DropdownHeadline = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    letter-spacing: 0;
    font-weight: 700;
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 40px;
        padding: 0;
        display: block;
    }
`;

export const HeadlineText = styled.h1`
    margin-bottom: 0;
    font-size: 26px;
    line-height: 30px;
    margin-top: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 42px;
    }
`;

export const Section = styled.section`
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    margin-bottom: 30px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        border-bottom-color: ${({ theme }) => theme.colors.backgroundLightGray};
        margin-bottom: 60px;
    }
`;

export const SubHeadline = styled.h2`
    margin-bottom: 0;
    font-size: 22px;
    line-height: 36px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 28px;
        margin-top: 40px;
        margin-bottom: 20px;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Column = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 20px 0;
    max-width: 50%;
`;

export const ColumnBold = styled.div`
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 20px 0;
    max-width: 50%;
`;

export const SelectContainerWrapper = styled.div`
    ${({ theme }) => theme.breakpoints.tn.mediaquery} {
        width: 150px;
    }
`;
