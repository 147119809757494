import { Text, View } from "@react-pdf/renderer";
import * as React from "react";

import { financialDetailDataEntryStyles } from "./FinancialDetailDataEntryStyles";

export enum IconType {
    Duration = 0,
    Distance,
    Costs,
}

interface IDetailsInfoDataProps {
    value?: string;
    description?: string;
}

const FinancialDetailDataEntryComponent: React.FunctionComponent<IDetailsInfoDataProps> = ({ value, description }) => {
    return (
        <View style={financialDetailDataEntryStyles.dataContainer}>
            <Text style={financialDetailDataEntryStyles.detailValue}>{value}</Text>
            <Text style={financialDetailDataEntryStyles.detailDescription}>{description}</Text>
        </View>
    );
};

export default FinancialDetailDataEntryComponent;
