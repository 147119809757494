import { getConfig } from "@src/config";
import { GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { DebugBadge } from "@src/shared/debugBadge/DebugBadge";
import React from "react";

import ShareMenu from "../shareMenu/ShareMenu";
import * as sc from "./Title.sc";

interface TitleProps {
    vehicle: GQLVehicleDetailQuery["vehicle"];
    shareMenuItems: React.ReactElement;
    disclaimerAsterisk?: string;
}

const Title: React.FC<TitleProps> = ({ vehicle, shareMenuItems, disclaimerAsterisk = "" }) => {
    const [shareMenuOpen, setShareMenuOpen] = React.useState(false);
    const showBadge = getConfig("showVehicleTypeBadge");

    function handleShareButtonClick() {
        setShareMenuOpen(true);
    }
    function handleShareMenuClose() {
        setShareMenuOpen(false);
    }

    return (
        <sc.CarTitleContainer>
            <div>
                <sc.HorizontalLine />
                <sc.CarTitle>
                    {vehicle.model}
                    {disclaimerAsterisk}
                    {showBadge && <DebugBadge type={vehicle.type} />}
                </sc.CarTitle>
            </div>

            <sc.ShareButtonDesktopContainer onClick={handleShareButtonClick}>
                <ShareMenu shareMenuOpen={shareMenuOpen} handleShareMenuClose={handleShareMenuClose} shareMenuItems={shareMenuItems} />
            </sc.ShareButtonDesktopContainer>
        </sc.CarTitleContainer>
    );
};

export default Title;
