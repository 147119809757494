import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    line-height: 25px;
    font-size: 18px;
    margin: 0 20px;
    background-color: white;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin: 0;
        padding: 20px;
        margin-top: 80px;
        border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    }
`;

export const Headline = styled.h2`
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        display: block;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        margin: 0;
        margin-bottom: 20px;
    }
`;

export const InfoText = styled.div`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 40px;

    a {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        display: inline-block;
    }
`;

export const Disclaimer = styled.p`
    margin-top: 30px;
    margin-bottom: 0;
`;

export const SubmitWrapper = styled.div`
    padding-top: 15px;
    max-height: 65px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
        margin-top: 20px;
        width: 300px;
    }
`;

export const ButtonWrapper = styled.div`
    text-align: center;
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        text-align: left;
    }
`;

export const BoniButtonText = styled.div`
    text-transform: uppercase;
`;

export const Loading = styled.div`
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: none;
    display: block;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 46px;
        width: 46px;
        animation: rotate 0.8s infinite linear, fadeInLoading 2s linear;
        border: 2px solid ${({ theme }) => theme.colors.typo};
        border-right-color: transparent;
        border-radius: 50%;
        opacity: 0.6;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes fadeInLoading {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.6;
        }
    }
`;

export const ResultWrapper = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    border: 1px solid ${({ theme }) => theme.colors.borderLightGray};
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    text-align: left;
    padding: 10px;

    strong {
        font-size: 31px;
        display: block;
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        line-height: 38px;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        border: none;
        padding: 0;
    }
`;

export const RepeatButton = styled.div`
    margin-top: 30px;
`;

export const FormContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
`;

export const StyledForm = styled.form`
    width: 100%;
`;
