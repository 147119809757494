import { getConfig } from "@src/config";
import { GQLFinanceInformationParameter } from "@src/graphql.apollo.generated";
import { useFinanceRuntimeOptions } from "@src/shared/context/searchfilter/Options";
import { DefaultReactSelectStyle } from "@src/shared/searchResults/searchfilter/filter/ReactSelectStyle";
import React from "react";
import { Field } from "react-final-form";
import ReactSelect from "react-select";

import InfoIcon from "../../infoIcon/InfoIcon";
import { Column, Error, InfoIconWrapper, Root, Row, SelectContainerWrapper, Unit } from "../formStyles.sc";

interface SelectProps {
    parameter: GQLFinanceInformationParameter;
}

const Select: React.FC<SelectProps> = ({ parameter }) => {
    const financeRuntimeOptions = useFinanceRuntimeOptions(getConfig("scope").domain);
    let options = parameter.options ?? undefined;

    if (parameter.id === "Duration") {
        options = parameter.options?.filter((opt) => financeRuntimeOptions.find((runtime) => runtime.value === Number(opt.value)));
    }

    return (
        <Root key={parameter.id}>
            <Row>
                <Column>{parameter.label} </Column>
                <Column isValueColumn>
                    {parameter.unit && <Unit>{parameter.unit}</Unit>}
                    <SelectContainerWrapper>
                        {parameter.defaultOption && parameter.id && (
                            <Field
                                name={parameter.id}
                                initialValue={parameter.defaultOption}
                                render={({ input }) =>
                                    options &&
                                    parameter.defaultOption && (
                                        <ReactSelect
                                            {...input}
                                            onChange={(option: { value: any; label: string }) => input.onChange(option ? option.value : "")}
                                            options={options}
                                            isSearchable={false}
                                            classNamePrefix={"react-select"}
                                            value={options.filter((option) => option.value === input.value)}
                                            styles={DefaultReactSelectStyle}
                                            isLightbox
                                        />
                                    )
                                }
                            />
                        )}
                    </SelectContainerWrapper>
                    {parameter.help && (
                        <InfoIconWrapper>
                            <InfoIcon alignment="right" infoText={<div dangerouslySetInnerHTML={{ __html: parameter.help ?? "" }} />} isClickable />
                        </InfoIconWrapper>
                    )}
                </Column>
            </Row>
            <Error dangerouslySetInnerHTML={{ __html: parameter.error?.description ?? "" }} />
        </Root>
    );
};

export default Select;
