import styled from "styled-components";

export const Error = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 7px 0;
    line-height: 16px;
    font-size: 12px;
    display: none;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        display: block;
    }
`;
