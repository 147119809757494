import { View } from "@react-pdf/renderer";
import { GQLPriceInfoFragment } from "@src/graphql.apollo.generated";
import { IFilter } from "@src/shared/context/searchfilter/IFilter";
import { getFormattedCurrency } from "@src/shared/utils/getFormattedCurrency";
import FinancialDetailDataEntryComponent from "@src/shared/vehicleDetail/pdf/financialdetail/FinancialDetailDataEntryComponent";
import * as React from "react";
import { useIntl } from "react-intl";

import { financialDetailData } from "./FinancialDetailDataStyles";
interface IDetailsInfoProps {
    priceInfo?: GQLPriceInfoFragment;

    filter: IFilter;
}

const FinancialDetailDataComponent: React.FunctionComponent<IDetailsInfoProps> = ({ priceInfo, filter }) => {
    const intl = useIntl();

    const downPayment = getFormattedCurrency({ value: priceInfo?.finCalcData?.downPayment || 0 }, intl);

    const durationValue = intl.formatMessage(
        {
            id: "leaseme.runtime.value",
            defaultMessage: "{financeRuntime} Monate",
        },
        {
            financeRuntime: intl.formatNumber(filter.financeRuntime),
        },
    );
    const distanceValue = `${intl.formatNumber(filter.kmPerYear)} ${intl.formatMessage({
        id: "leaseme.km",
        defaultMessage: "km",
    })}`;
    const residualValue = getFormattedCurrency(
        {
            value: priceInfo?.finCalcData?.residualValue || 0,
        },
        intl,
    );

    return (
        <View style={financialDetailData.infoContainer}>
            <FinancialDetailDataEntryComponent
                value={durationValue}
                description={intl.formatMessage({
                    id: "leaseme.runtime.label",
                    defaultMessage: "Laufzeit",
                })}
            />
            <FinancialDetailDataEntryComponent
                value={distanceValue}
                description={intl.formatMessage({
                    id: "leaseme.kmPerYear.long.label",
                    defaultMessage: "Kilometer/Jahr",
                })}
            />
            <FinancialDetailDataEntryComponent
                value={downPayment}
                description={intl.formatMessage({
                    id: "leaseme.downPayment.label",
                    defaultMessage: "Eigenleistung",
                })}
            />
            <FinancialDetailDataEntryComponent
                value={residualValue}
                description={`${intl.formatMessage({
                    id: "leaseme.residualValue.label",
                    defaultMessage: "Restwert",
                })}`}
            />
        </View>
    );
};

export default FinancialDetailDataComponent;
